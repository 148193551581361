import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { TranslationService } from "@ds-shared/common-services/utility-services/translation.service";
import {
	ColumnModel,
	CommonInsightModel,
	CommonModel,
	CommonPopUpModel,
	CommonSearchModel,
	ModuleType,
} from "@ds-shared/models/common.model";
import { DocumentCollection } from "ngx-jsonapi";
import { Subject, takeUntil, Subscription, filter } from "rxjs";
import {
	ScorecardList,
	ScorecardListService,
	ScorecardListExtraService,
	ScorecardService,
	HarmonisedListExtraService
} from "@ds-private-layouts/scorecard/scorecard.service";
import { ScorecardListColumns } from "./column.config";
import {
	COL,
	GRIDTYPE,
	LOCALFILTER,
	PAYLOAD
} from "@ds-shared/enums/common.enum";
import { RolePermissions } from "@ds-shared/models/access-matrix.model";
import { AccessMatrixService } from "@ds-shared/common-services/utility-services/access-matrix.service";
import { CommonGridPopUpComponent } from "@ds-shared/common-components/common-grid-pop-up/common-grid-pop-up.component";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { Modules } from "@ds-shared/enums/modules.enum";
import { AccessMatrix } from "@ds-shared/enums/access-matrix.enum";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { ScorecardHeaderTabList } from "@ds-shared/models/scorecard.model";
import { ProductType } from "@ds-shared/models/scorecard.model";
import { ILOCAL } from "@ds-shared/models/local-filter.model";

type ScorecardListType = 'product' | 'store' | 'brand' | 'keyword' | 'category';
@Component({
	selector: "app-scorecard-list",
	templateUrl: "./scorecard-list.component.html",
	styleUrls: ["./scorecard-list.component.scss"]
})
export class ScorecardListComponent implements OnInit, OnDestroy {
	public scorecardList: DocumentCollection<ScorecardList>;
	public scorecardBredcrumb: string[] = [];
	public cols: ColumnModel[];
	public active = 0;
	public NoData = false;
	public sort: string[] = [];
	public loadingData = false;
	public page = 1;
	public pageSize: number;
	public itemPerPage = 10;
	public GRIDTYPE = GRIDTYPE;
	public exportPath = "";
	public exportParams = {};
	private initialColumns: string;
	private rawEntitiesAbsentList = ["keyword"];
	public moduleType: ModuleType = {
		name: "product-scorecard",
		style: {
			tableStyle: {
				height: "calc(100vh - 19rem)"
			},
			tableWrapper: {
				"border-bottom": "none",
				"border-radius": "0rem"
			},
			noDataStyle: {
				height: "31rem"
			}
		},
		pagination: {
			show: true
		},
		defaultSort: {
			key: "attributes.created_at",
			order: "up",
			active: true
		}
	};
	private $destroy: Subject<boolean> = new Subject();
	public searchedName: UntypedFormControl = new UntypedFormControl("");
	public searchObj: CommonSearchModel = {
		text: "",
		placeholder: "Search",
		style: { width: "18rem" }
	};
	/***** Local Filters *****/
	
  public permittedLocalFilter = [LOCALFILTER.STORE, LOCALFILTER.CATEGORY_L1,LOCALFILTER.CATEGORY_L2,LOCALFILTER.CATEGORY, LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL,LOCALFILTER.TAGS,
	LOCALFILTER.COUNTRY,
	LOCALFILTER.PRICE_RANGE,
	LOCALFILTER.SEGMENT, LOCALFILTER.PRODUCT_SEGMENT, LOCALFILTER.KEYWORD];
  public localFilters: any = { store: null, brand: null, category: null,category_l1: null,category_l2: null ,segment: null, status: null , retail_channel: null, aiService: null ,
		tags:null,
		country:null,
		ratings:null,
		price_range:null ,
		product_segment: null,
		keyword: null,
	}
	
	public optionalFilterParams = {
	style: { height: "2.25rem", width: "2.25rem" },
    filterRequired: {store: true, brand: false, category: true, category_l1:true,category_l2:true,segment: false, status: true, retail_channel: true, aiService: false ,
			tags:true,
			country:true,
			ratings:true,
			price_range:true,
			product_segment: true,
			keyword: true,
			exclude_by_keywords:true
	}
}
	/***** Local Filters *****/
	public optional = false;
	@Input() public listType: ScorecardListType;
	public shareOfSearchType = ["keyword"];
	private timelineFilter;
	public permissions: RolePermissions = new RolePermissions();
	public cqPermissions: RolePermissions = new RolePermissions();
	public exportedFileName;
	public clearLastCall: Subscription;
	public isHarmonised: boolean;
	public requiredFilters:{[key in ScorecardListType]: ILOCAL[]}  = {
		product: [
			LOCALFILTER.STORE,
			LOCALFILTER.CATEGORY,
			LOCALFILTER.CATEGORY_L1,
			LOCALFILTER.CATEGORY_L2,
			LOCALFILTER.RETAIL_CHANNEL,
			LOCALFILTER.TAGS,
			LOCALFILTER.COUNTRY,
			LOCALFILTER.RATINGS,
			LOCALFILTER.PRICE_RANGE,
			LOCALFILTER.SEGMENT,
			LOCALFILTER.PRODUCT_SEGMENT,
		],
		store: [LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY, LOCALFILTER.SEGMENT],
		brand: [LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY],
		keyword: [LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL],
		category: [LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL]
	};

	public tabList: ScorecardHeaderTabList[] = [
		{
			name: "Show User Tracked",
			value: GRIDTYPE.USERONLY,
			isHidden: false,
			countKey: ""
		},
		{ name: "Show All", value: GRIDTYPE.ALL, isHidden: false, countKey: "" },
		{
			name: "Show",
			value: GRIDTYPE.HARMONISED,
			isHidden: false,
			countKey: ""
		}
	];
	@ViewChild(CommonGridPopUpComponent)
	private commonGridPopUpComponent: CommonGridPopUpComponent;
	public toggleManageColumns = true;
	public insightInfo: CommonInsightModel = {
		type: "tracker",
		title: "Insights based on latest data run",
		path: "scorecard/suggest-trackers",
		payload: null
	};
	public scorecardCounts;
	public bulkActionParams = {};
	public globalScorecardFilterParam = {};
	public hideColumn: string[] = ["Tracked By"];
	// Product Type Dropdown
	public productType: ProductType[] = [
		{
			id: 1,
			label: "Active Products",
			code: "active"
		},
		{
			id: 2,
			label: "Discarded Products",
			code: "discarded"
		},
		{
			id: 3,
			label: "Products with NO BRAND",
			code: "no_brand"
		}
	];
	public defaultProductType = this.productType[0];
	public showCurrencyFilter = true;
	constructor(
		private router: Router,
		public commonFunctions: CommonFunctionsService,
		public translationService: TranslationService,
		public dataTransferService: DataTransferService,
		private scorecardListService: ScorecardListService,
		private scorecardService: ScorecardService,
		private scorecardListExtraService: ScorecardListExtraService,
		private accessMatrixService: AccessMatrixService,
		private localStorageService: LocalStorageService,
		private HarmonisedListExtraService: HarmonisedListExtraService,
		private globalService: GlobalService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
		this.cqPermissions = this.accessMatrixService.getPermissionByModule(
			AccessMatrix["Content Quality"]
		);
		this.dataTransferService.refreshProfileGrid$
			.pipe(takeUntil(this.$destroy))
			.subscribe((res) => {
				if (res) {
					this.triggerFromBeginning(true);
					this.insightInfo = { ...this.insightInfo };
					this.callMenuCount();
					this.dataTransferService.sendRefreshProfileGridRequest(false);
				}
			});
		this.dataTransferService.signalOnHarmonisationCrud$
			.pipe(
				filter((res) => res),
				takeUntil(this.$destroy)
			)
			.subscribe((res) => {
				this.callMenuCount();
			});
		HarmonisedListExtraService.register();
		scorecardListExtraService.register();
		scorecardListService.register();
	}

	ngOnInit(): void {
		this.callMenuCount();
		this.scorecardBredcrumb[0] = this.shareOfSearchType.includes(this.listType)
			? "Share of Search"
			: this.listType === "category"
			? "Discover"
			: "Scorecard Tracking";
		this.scorecardBredcrumb[1] = ["store", "product", "brand"].includes(
			this.listType
		)
			? `${this.listType} Tracking`
			: this.listType;
		this.localStorageService.set("listType", this.listType);
		this.moduleType = {
			...this.moduleType,
			name: `${this.listType}-scorecard`,
			bulkAction:
				this.tabList[this.active].value === GRIDTYPE.USERONLY ||
				(this.tabList[this.active].value === GRIDTYPE.ALL &&
					this.listType === "product")
		};
		this.tabList = this.tabList.map((ele: ScorecardHeaderTabList) => {
			let elName;
			const hideTab =
				(this.rawEntitiesAbsentList.includes(this.listType) &&
					ele.value === "all") ||
				this.listType === "keyword"
					? true
					: ele.isHidden;
			elName =
				this.listType !== "category" ? `${this.listType}s` : "categories";
			return {
				name: `${ele.name} ${elName}`,
				value: ele.value,
				isHidden: hideTab,
				countKey: this.countKey(ele.value)
			};
		});
		if (this.listType !== "keyword")
			this.localStorageService.set(
				this.localStorageService.scorecardDefaultListType,
				this.tabList[0].value
			);
		if (
			this.tabList[this.active].value === GRIDTYPE.ALL &&
			this.listType === "product"
		) {
			this.globalScorecardFilterParam = {
				...this.globalScorecardFilterParam,
				"filter[is_discarded]": this.defaultProductType.code === "discarded"
			};
		}
		this.decideLocalFilterVisibility();
		this.commonFunctions.clearScorecardLocalFilters();
		this.triggerFromBeginning();
		this.insightInfo = {
			...this.insightInfo,
			title: `Recommended ${
				this.listType !== "category"
					? `${this.commonFunctions.capitalizeFirstLetter(this.listType)}s`
					: "Categories"
			}`,
			path:
				this.listType === "keyword"
					? "scorecard/suggest-keyword-trackers"
					: this.insightInfo.path,
			payload: { "filter[type]": this.listType }
		};
	}

	private countKey(gridType) {
		const keyMap = {
			[GRIDTYPE.ALL]: `all_${this.listType}`,
			[GRIDTYPE.USERONLY]: this.listType,
			[GRIDTYPE.HARMONISED]: `harmonised_${this.listType}`
		};
		return keyMap[gridType] || this.listType;
	}

	private callMenuCount() {
		this.scorecardCounts = null;
		this.globalService
			.secondaryMenuCount(Modules.scorecard)
			.pipe(takeUntil(this.$destroy))
			.subscribe((res: CommonModel) => {
				this.scorecardCounts = res.data;
			});
	}

	private decideLocalFilterVisibility() {
		if (this.tabList[this.active].value === GRIDTYPE.ALL) {
			this.requiredFilters = {
				product: [ LOCALFILTER.STORE, LOCALFILTER.CATEGORY, LOCALFILTER.CATEGORY_L1, LOCALFILTER.CATEGORY_L2, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.TAGS, LOCALFILTER.COUNTRY, LOCALFILTER.RATINGS, LOCALFILTER.PRICE_RANGE, LOCALFILTER.SEGMENT,  LOCALFILTER.BRAND, LOCALFILTER.PRODUCT_SEGMENT, LOCALFILTER.KEYWORD, LOCALFILTER.EXCLUDE_BY_KEYWORDS],
				store: [ LOCALFILTER.RETAIL_CHANNEL,LOCALFILTER.COUNTRY, LOCALFILTER.SEGMENT],
				brand: [ LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY],
				keyword: [LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY],
				category: [LOCALFILTER.RETAIL_CHANNEL,LOCALFILTER.COUNTRY]
			};
		} else {
			this.requiredFilters = {
				product: [ LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.AI_Services, LOCALFILTER.COUNTRY ],
				store: [ LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY, LOCALFILTER.SEGMENT ],
				brand: [ LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY ],
				keyword: [ LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY ],
				category: [ LOCALFILTER.STATUS, LOCALFILTER.RETAIL_CHANNEL, LOCALFILTER.COUNTRY ]
			};
		}

		this.permittedLocalFilter = this.requiredFilters[this.listType];
		for (const item  in this.optionalFilterParams.filterRequired) {
			this.optionalFilterParams.filterRequired[item] =
				this.requiredFilters[this.listType].includes(item as ILOCAL);
		}
	}

	private triggerFromBeginning(isFromUpdate = false) {
		this.scorecardService.register();
		this.scorecardListService.changeType(
			this.tabList[this.active].value === GRIDTYPE.USERONLY
				? "profile"
				: this.tabList[this.active].value === GRIDTYPE.HARMONISED
				? "harmonised"
				: this.listType
		);

		this.scorecardListService.register();
		this.scorecardListExtraService.register();
		if (!isFromUpdate) this.setCols();
		if (
			this.localStorageService.get(this.localStorageService.scorecardPage) ||
			this.localStorageService.get(
				this.localStorageService.scorecardPageSize
			) ||
			this.localStorageService.get(
				this.localStorageService.scorecardDefaultListType
			) ||
			this.localStorageService.get(
				this.localStorageService.scorecardAllLocalFilters
			) ||
			this.localStorageService.get(
				this.localStorageService.scorecardUserOnlyLocalFilters
			) ||
			this.localStorageService.get(
				this.localStorageService.scorecardSearched
			) ||
			this.localStorageService.get(this.localStorageService.scorecardSort) ||
			this.localStorageService.get(this.localStorageService.scorecardCol)
		) {
			this.getSessionData();
		} else {
			this.initData();
		}
		this.exportExcelParamaterGenerator();
	}

	public getSessionData() {
		if (this.localStorageService.get(this.localStorageService.scorecardPage)) {
			this.page = Number(
				this.commonFunctions.getDecodedData(
					this.localStorageService.get(this.localStorageService.scorecardPage)
				)
			);
		}
		if (
			this.localStorageService.get(this.localStorageService.scorecardPageSize)
		) {
			this.pageSize = Number(
				this.commonFunctions.getDecodedData(
					this.localStorageService.get(
						this.localStorageService.scorecardPageSize
					)
				)
			);
			this.itemPerPage = this.pageSize;
		}
		if (
			this.localStorageService.get(
				this.localStorageService.scorecardDefaultListType
			)
		) {
			this.onListTypeChange(
				this.localStorageService.get(
					this.localStorageService.scorecardDefaultListType
				),
				true
			);
		}
		if (
			this.localStorageService.get(
				this.localStorageService.scorecardAllLocalFilters
			) ||
			this.localStorageService.get(
				this.localStorageService.scorecardUserOnlyLocalFilters
			)
		) {
			const tempEve =
				this.localStorageService.get(
					this.localStorageService.scorecardDefaultListType
				) === GRIDTYPE.ALL
					? this.localStorageService.get(
							this.localStorageService.scorecardAllLocalFilters
					  )
					: this.localStorageService.get(
							this.localStorageService.scorecardUserOnlyLocalFilters
					  );
			let parsedFilters;
			if (tempEve) {
				parsedFilters = JSON.parse(
					this.commonFunctions.getDecodedData(tempEve)
				);
			}
			this.onOptionalFilterUpdate(
				parsedFilters,
				this.localStorageService.get(
					this.localStorageService.scorecardDefaultListType
				),
				true
			);
		}
		if (
			this.localStorageService.get(this.localStorageService.scorecardSearched)
		) {
			this.onSearch(
				this.commonFunctions.getDecodedData(
					this.localStorageService.get(
						this.localStorageService.scorecardSearched
					)
				),
				true
			);
		}
		this.initData(true);
	}

	private exportExcelParamaterGenerator() {
		const exportPathObj = {
			[GRIDTYPE.ALL]: this.listType,
			[GRIDTYPE.USERONLY]: "profile",
			[GRIDTYPE.HARMONISED]: "harmonised"
		};
		exportPathObj[this.tabList[this.active].value];
		this.exportPath = `scorecard/export-${
			exportPathObj[this.tabList[this.active].value]
		}-list`;
	}

	public setCols(): void {
		this.initialColumns = JSON.stringify(
			ScorecardListColumns[this.listType].cols
		);
		this.cols = JSON.parse(
			JSON.stringify(
				ScorecardListColumns[this.listType].filterColumns([
					this.tabList[this.active].value
				])
			)
		);
		const temp = this.cols;
		temp[0]["isChecked"] = true;
		temp[temp.length - 1]["isChecked"] = true;
		this.hideColumn = ["Tracked By"];
		if (
			this.tabList[this.active].value === GRIDTYPE.ALL &&
			this.listType === "product"
		) {
			temp[0]["isChecked"] = this.defaultProductType.code !== "discarded";
			temp[temp.length - 1]["isChecked"] = this.defaultProductType.code !== "discarded";
			this.hideColumn =
				this.defaultProductType.code === "discarded"
					? ["Tracked By", "", "Actions"]
					: ["Tracked By"];
		}
		this.cols = [...temp];
		if (
			this.listType === "product" &&
			this.tabList[this.active].value === GRIDTYPE.USERONLY
		) {
			if (!this.cqPermissions.read_access) this.cols.splice(3, 1);
		}
	}
	private initData(keepCurrentPage?: boolean): void {
		if (keepCurrentPage) this.getProfiles(this.page);
		else this.getProfiles(1);
	}

	public onPageChange(eve: number): void {
		this.localStorageService.set(
			this.localStorageService.scorecardPage,
			this.commonFunctions.getEncodedData(eve)
		);
		this.page = eve;
		this.getProfiles(this.page);
	}
	public onPageSizeChange(eve: number): void {
		this.localStorageService.set(
			this.localStorageService.scorecardPageSize,
			this.commonFunctions.getEncodedData(eve)
		);
		this.localStorageService.set(
			this.localStorageService.scorecardPage,
			this.commonFunctions.getEncodedData(1)
		);
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.getProfiles(1);
	}

	private getProfiles(page: number): void {
		this.scorecardList = new DocumentCollection();
		const param: any = {};
		param[`${PAYLOAD.SEARCH}`] = this.searchObj.text?.trim()
		if (this.tabList[this.active].value === GRIDTYPE.USERONLY) {
			param["profile_type"] = this.listType;
		}
		this.permittedLocalFilter.forEach((item: ILOCAL) => {
			param[this.localFilters?.[item]?.payloadString] = this.localFilters?.[
				item
			]?.multiple
				? this.commonFunctions.getMappedData(
						this.localFilters?.[item]?.selected,
						this.localFilters?.[item]?.meta?.key
				  )
				: item === "country"
				? this.localFilters?.[item]?.selected?.id
				: this.localFilters?.[item]?.selected?.attributes?.code ? this.localFilters?.[item]?.selected?.attributes?.code:this.localFilters?.[item]?.selected;
		});
		if (this.localFilters.price_range?.selected.length > 0) {
			param["min_price"] = this.localFilters.price_range?.selected[0];
			param["max_price"] = this.localFilters.price_range?.selected[1];
		}
		if (
			this.tabList[this.active].value === GRIDTYPE.ALL &&
			this.listType === "product"
		) {
			param["is_discarded"] = this.defaultProductType.code === "discarded";
		}
		this.exportParams = this.commonFunctions.filterPayload({
			...param,
			sort: this.sort
		});
		this.exportedFileName = this.commonFunctions.getDynamicExportFileName(
			null,
			this.listType
		);
		if (this.defaultProductType.code==="no_brand") param["all_no_brands"] = true;
		if (
			!this.sort?.length &&
			this.moduleType.defaultSort?.active &&
			this.tabList[this.active].value === GRIDTYPE.ALL &&
			(this.listType === "store" || this.listType === "brand")
		) {
			this.sort = [
				`${this.moduleType.defaultSort.order === "up" ? "-" : ""}${
					this.moduleType.defaultSort.key.split(".")[
						this.moduleType.defaultSort.key.split(".")?.length - 1
					]
				}`
			];
		}
		this.clearLastCall?.unsubscribe();
		this.clearLastCall = this.scorecardListService
			.all({
				page: { number: page || 1, size: this.pageSize || 10 },
				remotefilter: this.commonFunctions.filterPayload(param),
				sort: this.sort,
				beforepath: "scorecard"
			})
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(res) => {
					this.loadingData = true;
					if (res.source == "server" && res.is_loading == false) {
						if (res.data.length == 0) {
							this.NoData = true;
							this.loadingData = true;
						} else {
							this.loadingData = false;
							this.NoData = false;
							this.scorecardList = res;
						}
					} else {
						this.loadingData = true;
						this.NoData = false;
					}
				},
				(error): void => {
					this.loadingData = true;
					this.NoData = true;
				}
			);
	}

	public sortData(data: string[]): void {
		this.sort = data;
		this.moduleType.defaultSort.active = false;
		this.initData(true);
	}

	public onSearch(text: string, isFromSession?): void {
		this.localStorageService.set(
			this.localStorageService.scorecardSearched,
			this.commonFunctions.getEncodedData(text)
		);
		this.searchObj = { ...this.searchObj, text: text };
		if (!isFromSession) {
			if (this.searchObj.text.length) this.initData();
			else this.initData(true);
		}
		this.bulkActionParams = {
			...this.bulkActionParams,
			[`filter[${PAYLOAD.SEARCH}]`]: this.searchObj.text?.trim()
		};
	}

	public onOptionalFilterUpdate(
		event: any,
		tabType: `${GRIDTYPE.ALL}` | `${GRIDTYPE.USERONLY}`,
		isFromSession?
	) {
		if (tabType === GRIDTYPE.ALL && event) {
			this.localStorageService.set(
				this.localStorageService.scorecardAllLocalFilters,
				this.commonFunctions.getEncodedData(JSON.stringify(event))
			);
		} else if (tabType === GRIDTYPE.USERONLY && event) {
			this.localStorageService.set(
				this.localStorageService.scorecardUserOnlyLocalFilters,
				this.commonFunctions.getEncodedData(JSON.stringify(event))
			);
		}
		this.localFilters = { ...event };
		this.optional = false;
		if (
			this.permittedLocalFilter.some((item: ILOCAL) => {
				return this.localFilters[item];
			})
		) {
			if (
				this.permittedLocalFilter.some((item: ILOCAL) => {
					return (
						this.localFilters[item]?.selected?.length > 0 ||
						(!this.localFilters[item]?.multiple &&
							this.localFilters[item]?.selected)
					);
				})
			)
				this.optional = true;
			if (!isFromSession) {
				this.initData();
			}
		}
		this.getBulkActionParam();
	}
	public resetData(): void {
		this.searchObj = { ...this.searchObj, text: "" };
		this.moduleType = {
			...this.moduleType,
			bulkAction:
				this.tabList[this.active].value === GRIDTYPE.USERONLY ||
				(this.tabList[this.active].value === GRIDTYPE.ALL &&
					this.listType === "product")
		};
		this.sort = [];
		this.bulkActionParams = JSON.parse(JSON.stringify({}));
		if (
			this.tabList[this.active].value === GRIDTYPE.ALL &&
			this.listType === "product"
		) {
			this.bulkActionParams = {
				...this.bulkActionParams,
				"filter[is_discarded]": this.defaultProductType.code === "discarded"
			};
			this.globalScorecardFilterParam = {
				...this.globalScorecardFilterParam,
				"filter[is_discarded]": this.defaultProductType.code === "discarded"
			};
		}
		this.setCols();
	}

	public onListTypeChange(view: any, isFromSessionToBeStored?) {
		this.toggleManageColumns = false;
		this.active = this.tabList.findIndex((obj) => obj.value === view);
		ScorecardListColumns[this.listType].cols = JSON.parse(this.initialColumns);
		this.decideLocalFilterVisibility();
		if (!isFromSessionToBeStored) {
			this.page = 1;
			this.pageSize = 10;
			this.itemPerPage = 10;
			this.resetData();
			this.triggerFromBeginning();
		}
		setTimeout(() => {
			this.toggleManageColumns = true;
		}, 0);
		this.moduleType["defaultSort"] = {
			key: "attributes.created_at",
			order: "up",
			active: true
		};
	}

	private openGridView(details) {
		const data: CommonPopUpModel = {
			type: `product-grid`,
			modelName: `Product List`,
			infoBanner: {
				name: `Products`,
				subName: { text: "" },
				value: "",
				subValue: null
			},
			gridInfo: {
				cols: [
					{
						id: 1,
						name: COL.PRODUCT_NAME,
						map: "attributes.name",
						sort: true,
						method: "productImage",
						component: "common-mapping",
						class: "",
						hasRedirect: true,
						isChecked: true,
						meta: { marketplaceLink: true }
					},
					{
						id: 2,
						name: COL.PRICE,
						map: "attributes.price",
						sort: true,
						method: "pricewithDiscountNewView",
						component: "common-mapping",
						class: "font-medium",
						hasRedirect: true,
						isChecked: true,
						style: {
							"th-class": "text-right",
							"td-class": "text-right"
						},
						meta: {
							isOwnCurrencyAvailable: true,
							currencyMap: "attributes.currency.symbol"
						}
					},
					{
						id: 3,
						name: COL.STORE,
						map: "attributes.store_name",
						sort: true,
						method: "storewithSegmentView",
						component: "common-mapping",
						class: "ellipsis",
						hasRedirect: true,
						isChecked: true,
						meta: { marketplaceLink: true }
					},
					{
						id: 4,
						name: COL.RETAIL_CHANNEL,
						map: "attributes.marketplace",
						sort: true,
						method: "logoFormat",
						component: "common-mapping",
						class: "",
						hasRedirect: false,
						isChecked: true
					},
					{
						id: 5,
						name: COL.LAST_UPDATED,
						map: "attributes.updated_at",
						sort: true,
						method: "dateFormat",
						component: "common-mapping",
						class: "",
						hasRedirect: false,
						isChecked: true
					},
					{
						id: 6,
						name: "",
						map: "",
						meta: { type: "products" },
						sort: false,
						method: "",
						component: "common-grid-action",
						class: "",
						hasRedirect: false,
						isChecked: true,
						style: {
							"th-class": "text-right",
							"td-class": "text-right"
						}
					}
				],
				path: "product-list",
				beforePath: "scorecard",
				params: {
					[PAYLOAD.MARKETPLACE_ID]: this.commonFunctions.getMappedData(
						this.timelineFilter?.marketplace,
						"id"
					),
					[PAYLOAD.ALL_PRODUCTS]: !("tracked_by" in details["attributes"]),
					[PAYLOAD.STORE_ID]:
						this.listType === "store" &&
						this.tabList[this.active].value === GRIDTYPE.ALL
							? [details.id]
							: null,
					[PAYLOAD.BRAND_ID]:
						this.listType === "brand" &&
						this.tabList[this.active].value === GRIDTYPE.ALL
							? [details.id]
							: null,
					[PAYLOAD.CATEGORY_ID]:
						this.listType === "category" &&
						this.tabList[this.active].value === GRIDTYPE.ALL
							? [details.id]
							: null,
					[PAYLOAD.PROFILES]:
						this.tabList[this.active].value === GRIDTYPE.USERONLY
							? [details.id]
							: null,
					[PAYLOAD.POPUP]: true
				}
			}
		};
		this.permittedLocalFilter.forEach((item: ILOCAL) => {
			data.gridInfo.params[this.localFilters?.[item]?.payloadString] = this
				.localFilters?.[item]?.multiple
				? this.commonFunctions.getMappedData(
						this.localFilters?.[item]?.selected,
						this.localFilters?.[item]?.meta?.key
				  )
				: this.localFilters?.[item]?.selected?.attributes?.code;
		});
		this.commonGridPopUpComponent.openModal(data);
	}

	public importProfile() {}

	public addProfile() {
		if (this.listType === "keyword") {
			this.router.navigate([`/share-of-search/${this.listType}/add-profile`]);
		} else if (this.listType === "category") {
			this.router.navigate([`/discover/share-of-category/add-profile`]);
		} else this.router.navigate([`/scorecard/${this.listType}/add-profile`]);
	}

	public onFilterData(event) {
		this.timelineFilter = event;
		this.triggerFromBeginning();
		this.dataTransferService.sendfireMenuCount("scorecard");
	}

	public onRefreshGrid(event) {
		if (event) {
			if (
				this.tabList[this.active].value === GRIDTYPE.ALL &&
				this.listType === "product"
			) {
				this.globalScorecardFilterParam = {
					...this.globalScorecardFilterParam,
					"filter[is_discarded]": this.defaultProductType.code === "discarded"
				};
			}
			this.triggerFromBeginning(true);
			this.callMenuCount();
			this.insightInfo = { ...this.insightInfo };
		}
	}

	public onCountClick(event) {
		this.openGridView(event);
	}
	onTabChange(index: number) {
		if(this.tabList[this.active].value === GRIDTYPE.HARMONISED) this.showCurrencyFilter = false;
		else this.showCurrencyFilter = true;
		this.commonFunctions.clearScorecardLocalFilters(
			this.tabList[this.active].value === GRIDTYPE.ALL &&
				this.listType === "product"
				? false
				: true
		);
		if (this.listType !== "keyword")
			this.localStorageService.set(
				this.localStorageService.scorecardDefaultListType,
				this.tabList[index].value
			);
		this.active = index;
		this.onListTypeChange(this.tabList[index].value);
		if (this.tabList[this.active].value === GRIDTYPE.ALL && this.listType === "product") {
			this.callProductswithNoBrand(this.productType[0], false);
		}
	}

	public getBulkActionParam(): void {
		const tempParams = {};
		this.permittedLocalFilter.forEach((item: ILOCAL) => {
			tempParams[`filter[${this.localFilters?.[item]?.payloadString}]`] = this
				.localFilters?.[item]?.multiple
				? this.commonFunctions.getMappedData(
						this.localFilters?.[item]?.selected,
						this.localFilters?.[item]?.meta?.key
				  )
				: item === "country"
				? this.localFilters?.[item]?.selected?.id
				: this.localFilters?.[item]?.selected?.attributes?.code;
		});
		if (this.localFilters.price_range?.selected.length > 0) {
			tempParams["filter[min_price]"] =
				this.localFilters.price_range?.selected[0];
			tempParams["filter[max_price]"] =
				this.localFilters.price_range?.selected[1];
		}
		this.bulkActionParams = { ...this.bulkActionParams, ...tempParams };
	}

	public callProductswithNoBrand(eve: ProductType, apiCall:boolean=true) {
		this.defaultProductType = eve;
		this.bulkActionParams = {
			...this.bulkActionParams,
			"filter[is_discarded]": this.defaultProductType.code === "discarded",
			"filter[all_no_brands]": eve.code === "no_brand"
		};
		const temp = this.cols;
		temp[0]["isChecked"] = this.defaultProductType.code !== "discarded";
		temp[temp.length - 1]["isChecked"] = this.defaultProductType.code !== "discarded";
		this.cols = [...temp];
		this.hideColumn =
			this.defaultProductType.code === "discarded"
				? ["Tracked By", "", "Actions"]
				: ["Tracked By"];
		this.globalScorecardFilterParam = {
			...this.globalScorecardFilterParam,
			"filter[is_discarded]": this.defaultProductType.code === "discarded",
			"filter[all_no_brands]": eve.code === "no_brand"
		};
		this.optionalFilterParams.filterRequired["brand"] = this.defaultProductType.code !== "no_brand";
		if (eve.code !== "no_brand") {
			delete this.bulkActionParams["filter[all_no_brands]"]
			delete this.globalScorecardFilterParam["filter[all_no_brands]"]
		}
		if (apiCall) this.getProfiles(1);
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
		ScorecardListColumns[this.listType].cols = JSON.parse(this.initialColumns);
	}
}
