<div class="d-flex">
    <div class="card cursor-pointer" (click)="onCardClicked($event)">
        <h4>
            {{cardInfo.title}}
        </h4>
        <div class="d-flex justify-content-between" *ngIf="!cardData.isLoading && cardData?.list ;else noData">
            <div class="me-3">
                <span class="value d-block">
                    {{cardData.list?.customer_count ? (cardData.list?.customer_count | number) : "-"}}
                </span>
                <span class="label">{{cardInfo.customerTitle}}</span>
            </div>
            <div>
                <span class="value d-block">
                    {{cardData.list?.competitor_count ? (cardData.list?.competitor_count | number) : "-"}}
                </span>
                <span class="label">{{cardInfo.competitorTitle}}</span>
            </div>
        </div>
        <ng-template #noData>
            <div class="no-data-common">
                <div *ngIf="!cardData.isLoading" class="no-data-found" translate>
                    <div class="no-data-text" translate>No data present</div>
                </div>
                <div *ngIf="cardData.isLoading" class="loading-panel">
                    <img ngSrc="/assets/images/spinner.svg" alt=""  height="200" width="200"/>
                </div>
            </div>
        </ng-template>
    </div>
</div>