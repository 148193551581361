import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NotificationsService } from "@ds-private-layouts/notifications/notifications.service";
import { UserManagementService } from "@ds-private-layouts/user-management/user-management.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { TabListModel } from "@ds-shared/models/notification.model";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
	selector: "app-right-panel",
	templateUrl: "./right-panel.component.html",
	styleUrls: ["./right-panel.component.scss"]
})
export class RightPanelComponent implements OnInit, OnDestroy {
	public type: string;
	public subType: string;
	public profileData: any;
	public profileDataSubscription: Subscription;
	public notificationDataSubscription: Subscription;
	public notificationMarketplaceSubscription: Subscription;
	public rolesDataSubscription: Subscription;
	public userDataSubscription: Subscription;
	public marketplaceResponse: any;
	public marketplacePageNumber: number;
	public brand: string = "";
	public actionType: string = "";
	public notificationData: any;
	public moduleType: string = "";
	public close: boolean = false;
	public actionData;
	public activeURL: any = "";
	public roleData: any;
	public userData: any;
	public isCustomer: boolean = false;
	public tabList: TabListModel[] = [
		{
			id: 1,
			name: "Stores",
			value: "store",
			routePath: "stores"
		},
		{
			id: 2,
			name: "Products",
			value: "product",
			routePath: "products"
		},
		{
			id: 3,
			name: "Keywords",
			value: "keyword",
			routePath: "keywords"
		},
		{
			id: 4,
			name: "Categories",
			value: "category",
			routePath: "categories"
		},
		{
			id: 5,
			name: "Brands",
			value: "brand",
			routePath: "brands"
		}
	];
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notificationService: NotificationsService,
		private userManagementService: UserManagementService,
		private notificationMessageService: NotificationService,
		private commonFunctions: CommonFunctionsService
	) {}

	ngOnInit() {
		this.getModuleDetails();
	}

	getModuleDetails() {
		this.activeURL = String(this.route["_routerState"]["snapshot"]?.url);
		if (this.activeURL?.includes("add-notification")) {
			this.moduleType = "add-notification";
			this.actionType = "add";
			this.subType = this.route?.queryParams?.["_value"]?.notificationType;
		} else if (this.activeURL?.includes("add-profile")) {
			this.moduleType = "add-profile";
			this.actionType = "add";
			this.subType = this.route?.queryParams?.["_value"]?.p;
			this.type = "your";
		} else if (
			this.commonFunctions.getURLSegments()[0] == "notification"
		) {
			this.moduleType = this.commonFunctions.getURLSegments()[0];
			this.actionType = this.commonFunctions.getURLSegments()[2];
			this.subType = this.commonFunctions.getURLSegments()[1];
		} else if (
			this.commonFunctions.getURLSegments()[0] == "build"
		) {
			this.moduleType = this.commonFunctions.getURLSegments()[0];
			if (
				this.commonFunctions.getURLSegments()[3] ===
				"manage-products"
			) {
				this.subType = "manage-products";
				this.actionType = this.commonFunctions.getURLSegments()[3];
			} else if (
				this.commonFunctions.getURLSegments()[3] ==
				"manage-mappings"
			) {
				this.subType = "manage-mappings";
				this.actionType = this.commonFunctions.getURLSegments()[3];
			} else {
				this.actionType = this.commonFunctions.getURLSegments()[3];
				this.type = this.commonFunctions.getURLSegments()[1];
				this.subType = this.commonFunctions.getURLSegments()[2];
			}
		} else if (
			this.commonFunctions.getURLSegments()[0] == "scorecard" &&
			this.commonFunctions.getURLSegments()[1] !== "profile"
		) {
			this.moduleType = this.commonFunctions.getURLSegments()[0];
			this.subType = this.commonFunctions.getURLSegments()[2];
		}
		if (this.moduleType == "build") {
			if (this.actionType == "add") {
			} else if (
				this.actionType !== "manage-products" &&
				this.actionType !== "manage-mappings"
			) {
			}
		} else if (this.moduleType == "notification") {
			if (this.actionType == "edit") {
				this.getNotificationData(
					this.subType,
					this.commonFunctions.getURLSegments()[3]
				);
			}
		}
		if (
			this.commonFunctions.getURLSegments()[1] == "team-members"
		) {
			this.moduleType = this.commonFunctions.getURLSegments()[1];
			this.actionType = this.commonFunctions.getURLSegments()[2];
			if (this.actionType == "edit") {
				this.getUserData(this.commonFunctions.getURLSegments()[3]);
			}
		} else if (
			this.commonFunctions.getURLSegments()[2] ==
			"edit-user-details"
		) {
			this.moduleType = "manage-user";
		} else if (
			this.commonFunctions.getURLSegments()[0] == "user-management"
		) {
			this.moduleType = "user-management";
			if (this.commonFunctions.getURLSegments()[1] == "access-matrix") {
				this.type = "access-matrix";
				this.subType = this.commonFunctions.getURLSegments()[2];
				this.actionType = this.commonFunctions.getURLSegments()[3];
				if (this.actionType == "edit") {
					this.getRoleData(
						this.commonFunctions.getURLSegments()[4]
					);
				}
			}
		}
		if (this.moduleType == "competitor") {
			if (this.actionType == "add") {
			} else {
			}
		}
		if (
			this.commonFunctions.getURLSegments()[0] == "scorecard" &&
			this.commonFunctions.getURLSegments()[1] == "profile" &&
			!this.activeURL?.includes("add-notification")
		) {
			this.moduleType = "profile-scorecard";
			if (
				this.commonFunctions.getURLSegments()[2] ==
				"manage-products"
			) {
				this.subType = "manage-products";
			} else if (
				this.commonFunctions.getURLSegments()[2] ==
				"manage-mappings"
			) {
				this.subType = "manage-mappings";
			}
		}
	}

	shouldOpenPanel(): boolean {
		if (this.type == "competitor") {
			if (this.actionType == "edit") {
				return this.profileData != null;
			}
			return true;
		} else if (this.type == "your") {
			if (this.actionType == "edit") {
				return this.profileData != null;
			}
			return true;
		} else if (
			this.moduleType == "notification" ||
			this.moduleType == "add-notification"
		) {
			if (this.actionType == "edit") {
				return this.notificationData != null;
			}
			return true;
		} else if (this.type === "access-matrix") {
			if (this.actionType == "edit") return this.roleData != null;
			return true;
		} else if (this.moduleType == "team-members") {
			if (this.actionType == "edit") return this.userData != null;
			return true;
		} else return true;
	}

	onSuccessAction() {
		this.close = false;
		if (this.moduleType == "add-notification") {
			const queryParams = { ...this.route["snapshot"].queryParams };
			if (queryParams["notificationInfo"])
				queryParams["notificationInfo"] = null;
			if (queryParams["notificationType"])
				queryParams["notificationType"] = null;
			this.router.navigate([this.actionType === "edit" ? "../.." : ".."], {
				relativeTo: this.route,
				queryParams: queryParams,
				queryParamsHandling: "merge"
			});
		} else if (this.moduleType == "build" || this.subType == "roles") {
			this.router.navigate([
				`${this.commonFunctions.getURLSegments()[0]}/${this.commonFunctions.getURLSegments()[1]}/${this.commonFunctions.getURLSegments()[2]}`
			]);
		} else if (this.moduleType == "manage-user") {
			this.router.navigate([`/user-management/account-overview`]);
		} else if (this.moduleType == "notification") {
			this.router.navigate([this.actionType === "edit" ? "../.." : ".."], {
				relativeTo: this.route
			});
		} else {
			this.router.navigate([
				`${this.commonFunctions.getURLSegments()[0]}/${this.commonFunctions.getURLSegments()[1]}/${this.commonFunctions.getURLSegments()[2]}`
			]);
		}
	}
	onCloseAction() {
		this.close = true;
		if (this.moduleType == "add-notification") {
			const queryParams = { ...this.route["snapshot"].queryParams };
			if (queryParams["notificationInfo"])
				queryParams["notificationInfo"] = null;
			if (queryParams["notificationType"])
				queryParams["notificationType"] = null;
			this.router.navigate([this.actionType === "edit" ? "../.." : ".."], {
				relativeTo: this.route,
				queryParams: queryParams,
				queryParamsHandling: "merge"
			});
		} else if (this.moduleType == "build" || this.subType == "roles") {
			this.router.navigate([
				`${this.commonFunctions.getURLSegments()[0]}/${this.commonFunctions.getURLSegments()[1]}/${this.commonFunctions.getURLSegments()[2]}`
			]);
		} else if (this.moduleType == "manage-user") {
			this.router.navigate([`/user-management/account-overview`]);
		} else if (
			this.moduleType == "scorecard" ||
			this.moduleType === "profile-scorecard"
		) {
			this.router.navigate([".."], { relativeTo: this.route });
		} else if (this.moduleType == "notification") {
			this.router.navigate([this.actionType === "edit" ? "../.." : ".."], {
				relativeTo: this.route
			});
		} else {
			this.router.navigate([
				`${this.commonFunctions.getURLSegments()[0]}/${this.commonFunctions.getURLSegments()[1]}`
			]);
		}
	}

	getNotificationData(notificationType, notificationId) {
		this.notificationDataSubscription?.unsubscribe();
		this.notificationDataSubscription = this.notificationService
			.getNotificationData(
				this.getTypeFromRoute(notificationType),
				notificationId
			)
			.subscribe(
				(res: any) => {
					if (res) this.notificationData = res?.data;
				},
				(err: any) => {
					this.notificationData = null;
				}
			);
	}

	private getTypeFromRoute(type) {
		const obj = this.tabList.find((o) => o.routePath === type);
		return obj.value;
	}

	getRoleData(roleId) {
		this.rolesDataSubscription?.unsubscribe();
		this.rolesDataSubscription = this.userManagementService
			.getRoleInfo(roleId)
			.subscribe(
				(res: any) => {
					if (res) this.roleData = res?.data;
				},
				(err: any) => {
					this.roleData = null;
					this.notificationMessageService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}
	getUserData(userId) {
		this.rolesDataSubscription?.unsubscribe();
		this.rolesDataSubscription = this.userManagementService
			.getUserInfo(userId)
			.subscribe(
				(res: any) => {
					if (res) this.userData = res?.data;
				},
				(err: any) => {
					this.userData = null;
					this.notificationMessageService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}

	ngOnDestroy(): void {
		this.userDataSubscription?.unsubscribe();
		this.rolesDataSubscription?.unsubscribe();
		if (this.profileDataSubscription)
			this.profileDataSubscription.unsubscribe();
		if (this.notificationDataSubscription)
			this.notificationDataSubscription.unsubscribe();
		if (this.notificationMarketplaceSubscription)
			this.notificationMarketplaceSubscription.unsubscribe();
	}
}
