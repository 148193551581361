<div class="pricing-history-container product-graph-container position-relative" *ngIf="permissions['pricing'].read_access || permissions['sales'].read_access || permissions['availability'].read_access">
    <div class="container-head position-relative d-flex align-items-center justify-content-between">
        <div class="d-flex flex-row align-items-center gap-8">
         <span class="infoHeader" showTooltipOnTextOverflow [data]="title?title:tabObj[this.activeTab].name +' History'">{{title?title:tabObj[this.activeTab].name +' History'}}</span>
        <ng-template [ngIf]="!isCustomDate && dateFilter">
            <span class="d-inline info-text" [ngClass]="{'smSalesLabel': tabObj[this.activeTab].name === 'Availability'}" showTooltipOnTextOverflow *ngIf="dateConfig?.startDate == dateConfig?.endDate" [data]="'Showing '+tabObj[this.activeTab].type+' data for ' +getDisplayDate(dateConfig?.startDate)"></span>
            <span class="d-inline info-text" [ngClass]="{'smSalesLabel': tabObj[this.activeTab].name === 'Availability'}" showTooltipOnTextOverflow *ngIf="dateConfig?.startDate != dateConfig?.endDate"
            [data]="'Showing '+tabObj[this.activeTab].type+' data from ' +getDisplayDate(dateConfig?.startDate) + ' to '
            + getDisplayDate(dateConfig?.endDate)"></span>
        </ng-template>
        <ng-template [ngIf]="isCustomDate">
            <span class="d-inline info-text" [ngClass]="{'smSalesLabel': tabObj[this.activeTab].name === 'Availability'}" showTooltipOnTextOverflow *ngIf="filterParams.timeline?.startDate == filterParams.timeline?.endDate" [data]="'Showing data for ' +getDisplayDate(filterParams.timeline?.startDate)"></span>
            <span class="d-inline info-text" [ngClass]="{'smSalesLabel': tabObj[this.activeTab].name === 'Availability'}" showTooltipOnTextOverflow *ngIf="filterParams.timeline?.startDate != filterParams.timeline?.endDate"
            [data]="'Showing data from '+ getDisplayDate(filterParams.timeline?.startDate) +' to '
            + getDisplayDate(filterParams.timeline?.endDate)"></span>
        </ng-template>
        <ng-template [ngIf]="isDefaultTimeline">
            <span class="d-inline info-text" [ngClass]="{'smSalesLabel': tabObj[this.activeTab].name === 'Availability'}" showTooltipOnTextOverflow
            [data]="'Showing data from '+ getDisplayDate(defaultTimeline?.startDate) +' to '
            + getDisplayDate(defaultTimeline?.endDate)"></span>
        </ng-template>
        </div>
        <div class="d-flex gap-8 align-items-center">
            <div class="toggle_radio fs_lg" *ngIf="!takeControlOfTabs">
                <ng-container *ngFor="let tab of tabs">
                    <div *ngIf="!tabObj[tab].isHidden">
                        <input type="radio" id={{tab}} name={{tab}} [ngClass]="{ active: activeTab === tab }">
                        <label for={{tab}} (click)="onTabChange(tab)">
                            <p translate>{{tabObj[tab].name}}</p>
                        </label>
                    </div>
                </ng-container>
            </div>
            <app-common-action-dropdown [path]="exportPayloads.path" [params]="exportPayloads.params" [filename]="exportPayloads.fileName" [optionalParams]="exportPayloads.optionalParams" [imageDownloadObj]="imageDownloadObj" [disableIcon]="!pricingHistoryObj.categories?.length"></app-common-action-dropdown>
        </div>
    </div>
    <div class="container-body">
            <ng-container *ngIf="pricingHistoryObj.categories?.length; else noDailySalesProductData">
                <ng-container *ngIf="tabObj[this.activeTab].type==='sales';else others">
                    <app-stacked-column-chart [groupPadding]="0.1" [caller]="'salesTrend'" [scrollbarThreshold]="30" [pointWidth]="pricingHistoryObj.categories.length>5?20:30" [stackingStyle]="undefined"  [series]="pricingHistoryObj.chartData" [categories]="pricingHistoryObj.categories"></app-stacked-column-chart>
                </ng-container>
                <ng-template #others>
                        <app-spline-chart [currencyDetails]="currencyDetails" [backgroundColor]="" [xAxisRotationAngle]="xAxisRotationAngle" [data]="pricingHistoryObj.chartData" [categories]="pricingHistoryObj.categories" [isMarkerNeeded]="pricingHistoryObj.categories?.length===1"
                        [caller]="'pricingHistory'" [tooltipShared]="false" [leftYAxisTitle]="(tabObj[this.activeTab].yAxis | translate) +tabObj[this.activeTab].suffiyAxis" [rightYAxisTitle]="tabObj[this.activeTab].yRightAxis | translate"
                        [isMultipleYaxis]="['Pricing', 'Sales'].includes(tabObj[this.activeTab].name)?true:false" [scrollbarThreshold]="45" [customAlign]="'middle'" [customRotation]="'270'" [customRotationY]="'90'" [leftYaxisOffset]="undefined" [rightYaxisOffset]="undefined" [chartObj]="{spacing: [50,0, 5, 5]}" isDate="true">
                    </app-spline-chart>
                </ng-template>
                <div class="legends mb-3">
                    <div class="legends-container d-flex position-relative justify-content-end">
                        <div class="info-text" *ngFor="let legendItem of pricingHistoryObj.chartData">
                            <div class="info-color" [ngStyle]="{'background-color':legendItem.color}"></div><span
                                class="text">{{legendItem.name==='GMV'?'Sales':legendItem.name| translate}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- This is dummy plot for download Image -->
            <div *ngIf="pricingHistoryObj.categories?.length" id="pricingLegendDiv" class="d-none">
                <ng-container *ngIf="tabObj[this.activeTab].type==='sales';else others">
                    <app-stacked-column-chart [groupPadding]="0.1" [caller]="'salesTrend'" [scrollbarThreshold]="30" [pointWidth]="pricingHistoryObj.categories.length>5?20:30" [stackingStyle]="undefined"  [series]="pricingHistoryObj.chartData" [categories]="pricingHistoryObj.categories"></app-stacked-column-chart>
                </ng-container>
                <ng-template #others>
                        <app-spline-chart [backgroundColor]="" [xAxisRotationAngle]="-90" [data]="pricingHistoryObj.chartData" [categories]="pricingHistoryObj.categories" [isMarkerNeeded]="pricingHistoryObj.categories?.length===1"
                        [caller]="'pricingHistory'" [tooltipShared]="false" [leftYAxisTitle]="(tabObj[this.activeTab].yAxis | translate) +tabObj[this.activeTab].suffiyAxis" [rightYAxisTitle]="tabObj[this.activeTab].yRightAxis | translate"
                        [isMultipleYaxis]="['Pricing', 'Sales'].includes(tabObj[this.activeTab].name)?true:false" [scrollbarThreshold]="100" [customAlign]="'middle'" [customRotation]="'270'" [customRotationY]="'90'" [leftYaxisOffset]="undefined" [rightYaxisOffset]="undefined" [chartObj]="{spacing: [60, 40, 5, 15]}" isDate="true">
                    </app-spline-chart>
                </ng-template>
                <div class="legends mb-3 me-5">
                    <div class="legends-container d-flex position-relative justify-content-end">
                        <div class="info-text" *ngFor="let legendItem of pricingHistoryObj.chartData">
                            <div class="info-color" [ngStyle]="{'background-color':legendItem.color}"></div><span
                                class="text">{{legendItem.name==='GMV'?'Sales':legendItem.name| translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Code ends here -->
            <ng-template #noDailySalesProductData>
                <div class="no-data-common">
                    <div *ngIf="!pricingHistoryObj.isLoading" class="no-data-found" translate>
                        <div class="no-data-text" translate>{{customMessageToHandleCampaign}}</div>
                    </div>
                    <div *ngIf="pricingHistoryObj.isLoading" class="loading-panel">
                        <img ngSrc="/assets/images/spinner.svg" alt="" height="200" width="200"/>
                    </div>
                </div>
            </ng-template>
    </div>
</div>