<div class="overlay"></div>
<div class="right-panel">
    <div class="header-container d-flex align-items-center justify-content-between  text-align-right-rtl">
        <span class="d-flex" translate>{{actionType==='add' ? ("Add " + (profileType | titlecase) + " Tracker") :
            ("Update " + (profileType | titlecase) + " Tracker")}}</span>
        <i class="icon icon-Close2 cursor-pointer" (click)="onCloseAction()" placement="left"
            ngbTooltip="{{'Close' | translate}}" tooltipClass="info-custom-tooltip"></i>
    </div>
    <form [formGroup]="profileForm" class="buildForm text-align-right-rtl mt-3" (ngSubmit)="onSubmit()"
        (keydown.enter)="$event.preventDefault()" *ngIf="actionType==='add' || (actionType==='edit' && profileData)">
        <div class="profile-container">
            <div class="select-container" *ngIf="profileForm.get('profile_type').value==='category' && actionType==='add' && !profileInfo"> <label translate>Select
                    Category <sup>*</sup></label>
                <ng-select class="select-text-wrap productMatchingCategory" [notFoundText]="'No Data Found' | translate"
                    [loadingText]="'Loading...' | translate" [clearable]="false" [items]="categoryData.list"
                    placeholder="{{'Select Category' | translate}}" bindLabel="attributes.name"
                    [ngClass]="{ error: profileForm.controls['select_category'].invalid && (profileForm.controls['select_category'].dirty || profileForm.controls['select_category'].touched || submitted) }"
                    formControlName="select_category" (change)="onChange($event,'select_category')"
                    [loading]="categoryData.loading">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div *ngIf="item.id!==-1;else customOption">
                            <section class="d-flex align-items-center justify-content-between category-item">
                                <div class="item-name font-medium" translate>{{item.attributes.name}}</div>
                                <div class="product-count font-medium" translate>{{item.attributes.product_count}}</div>
                            </section>
                            <section>
                                <div class="text-balance-wrap">{{item.attributes.category_chain}}</div>
                            </section>
                        </div>
                        <ng-template #customOption>
                           <div class="custom-text-style">
                                {{item.attributes.name}}
                           </div> 
                        </ng-template>
                    </ng-template>
                </ng-select>
                <div class="errorLable"
                    *ngIf=" profileForm.controls['select_category'].invalid && (profileForm.controls['select_category'].dirty || profileForm.controls['select_category'].touched || submitted) ">
                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['select_category'].errors.required"
                        translate>Please select category</span>
                </div>
            </div>
            <div class="input-container" *ngIf="!['keyword','category'].includes(profileForm.get('profile_type').value) || categoryTrackerView==='custom'"> <label
                    for="profileName" translate>Tracker Name <sup>*</sup></label> <input type="text" id="profileName"
                    placeholder="{{'Enter Name' | translate}}" autocomplete="off"
                    [ngClass]="{ error: profileForm.controls['profile_name'].invalid && (profileForm.controls['profile_name'].dirty || profileForm.controls['profile_name'].touched || submitted) }"
                    formControlName="profile_name" />
                <div class="errorLable"
                    *ngIf=" profileForm.controls['profile_name'].invalid && (profileForm.controls['profile_name'].dirty || profileForm.controls['profile_name'].touched || submitted) ">
                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['profile_name'].errors.required"
                        translate>Please enter tracker name</span>
                    <span *ngIf="profileForm.controls['profile_name'].errors.pattern" translate>Special Characters and
                        Emojis
                        are not allowed</span>
                </div>
            </div>
            <div class="input-container" *ngIf="profileForm.get('profile_type').value==='keyword'"> <label
                    for="multiple_keywords" translate>Add Keywords <sup>*</sup><span class='filter-criteria-info'>
                        ({{'Upto 5 Keywords' |
                        translate}})</span></label> <input type="text" formControlName="multiple_keywords"
                    autocomplete="off" id="multiple_keywords"
                    (keydown.enter)="onCriteriaSetKeydown($event,'multiple_keywords',5)" placeholder="Enter Keyword"
                    (blur)="onCriteriaSetKeydown($event,'multiple_keywords',5)"
                    [ngClass]="{ error: (criteria.multiple_keywords?.length === 0 && (profileForm.controls['multiple_keywords'].dirty || profileForm.controls['multiple_keywords'].touched || submitted)) }" />
                <div class="errorLable"
                    *ngIf="(criteria.multiple_keywords?.length == 0 && (profileForm.controls['multiple_keywords'].dirty || profileForm.controls['multiple_keywords'].touched || submitted))">
                    <i class="icon icon-Error"></i> <span *ngIf="criteria.multiple_keywords?.length === 0"
                        translate>Please type keyword and press enter</span>
                </div>
                <div class="input_tag"> <span *ngFor="let skill of criteria.multiple_keywords; let i = index"
                        class="roundedcorner">
                        {{ skill
                        }}<i class="icon icon-Close2" (click)="dropCriteria(i,'multiple_keywords')"
                            *ngIf="actionType !== 'edit'"></i>
                    </span>
                </div>
                <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('multiple_keywords').value?.length"
                    (click)="onCriteriaSetKeydown($event,'multiple_keywords',5)"></i>
            </div>
            <div class="input-container profile-url" *ngIf="profileForm.get('profile_type').value!=='keyword'">
                <section class="d-flex align-items-center gap-2">
                    <label for="profileUrl" [innerHTML]="(profileForm.get('profile_type').value | titlecase) + ' URL' + ''"
                        translate>
                    </label>
                    <small (click)="showSampleURL(content)" class="sampleURLLink cursor-pointer">[{{'Sample URL' | translate}}]</small>
                    <sup>*</sup>
                </section> 
                <input type="text" id="profileUrl" placeholder="{{'Enter URL' | translate}}"
                    autocomplete="off"
                    [ngClass]="{ error: profileForm.controls['profile_url'].invalid && (profileForm.controls['profile_url'].dirty || profileForm.controls['profile_url'].touched || submitted) }"
                    formControlName="profile_url" (keydown)="onURLAction($event)" (blur)="onURLAction($event)" />
                <div class="errorLable"
                    *ngIf=" profileForm.controls['profile_url'].invalid && (profileForm.controls['profile_url'].dirty || profileForm.controls['profile_url'].touched || submitted) ">
                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['profile_url'].errors.required"
                        translate>Please
                        enter {{profileForm.get("profile_type").value | lowercase}} url</span> <span
                        *ngIf=" !profileForm.controls['profile_url'].errors.required && profileForm.controls['profile_url'].errors.invalid "
                        translate>Please enter valid url</span>
                </div>
                <i class="icon icon-Arrow-right enter-state"
                    *ngIf="profileForm?.controls?.profile_url?.status === 'VALID'"
                    (click)="onURLAction($event)"></i>
            </div>
            <div class="select-container"
                *ngIf="profileForm.get('profile_type').value==='keyword' || this.marketplaceDetail?.multiple"> <label
                    translate>Select Channel <sup>*</sup></label>
                <ng-select (close)="onMarketplaceDropdownClose()" class="select-text-wrap"
                    [notFoundText]="'No Data Found' | translate" [loadingText]="'Loading...' | translate" [clearable]="false"
                    [items]="marketplaceData.list" placeholder="{{'Select Channel' | translate}}" bindLabel="name"
                    [ngClass]="{ error: profileForm.controls['select_marketplace'].invalid && (profileForm.controls['select_marketplace'].dirty || profileForm.controls['select_marketplace'].touched || submitted) }"
                    (scrollToEnd)="getMarketplaceList()" (search)="searchInput('search_marketplace', $event)"
                    formControlName="select_marketplace" (change)="onChange($event,'select_marketplace')"
                    [loading]="marketplaceData.loading"> </ng-select>
                <div class="errorLable"
                    *ngIf=" profileForm.controls['select_marketplace'].invalid && (profileForm.controls['select_marketplace'].dirty || profileForm.controls['select_marketplace'].touched || submitted) ">
                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['select_marketplace'].errors.required"
                        translate>Please select
                        Channel</span>
                </div>
            </div>
            <div class="select-container" *ngIf="profileForm.get('profile_type').value!=='product'"> <label
                    translate>Sort By
                    <sup>*</sup></label>
                <ng-select class="select-text-wrap" #myInput [notFoundText]="'No Data Found' | translate"
                    [loadingText]="'Loading...' | translate" [clearable]="false" [items]="sortBy?.list"
                    placeholder="{{'Select Sort Option' | translate}}" bindLabel="name"
                    [ngClass]="{ error: profileForm.controls['sort_by'].invalid && (profileForm.controls['sort_by'].dirty || profileForm.controls['sort_by'].touched || submitted) }"
                    formControlName="sort_by" [loading]="sortBy?.loading"> </ng-select>
                <div class="errorLable"
                    *ngIf=" profileForm.controls['sort_by'].invalid && (profileForm.controls['sort_by'].dirty || profileForm.controls['sort_by'].touched || submitted) ">
                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['sort_by'].errors.required"
                        translate>Please
                        select sort option</span>
                </div>
            </div>
            <div class="input-container"
                *ngIf="profileForm.get('profile_type').value!=='product'">
                <label for="page_limit" translate>Page Limit <sup>*</sup></label> <input type="number" id="page_limit"
                    min="1" placeholder="{{'Enter Page Limit' | translate}}"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" autocomplete="off"
                    [ngClass]="{ error: profileForm.controls['page_limit'].invalid && (profileForm.controls['page_limit'].dirty || profileForm.controls['page_limit'].touched || submitted), success: (!profileForm.controls['page_limit']?.errors?.fieldMessage && profileForm.controls['page_limit']?.value>0) }"
                    formControlName="page_limit" />
                <div class="errorLable"
                    *ngIf=" profileForm.controls['page_limit'].invalid && (profileForm.controls['page_limit'].dirty || profileForm.controls['page_limit'].touched || submitted) ">
                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['page_limit'].errors.required"
                        translate>Please
                        enter
                        limit</span>
                    <span *ngIf="profileForm.controls['page_limit'].errors.fieldMessage"
                        translate>{{profileForm.controls['page_limit'].errors.fieldMessage}}</span>
                </div>
                <div class="successLable"
                    *ngIf="(!profileForm.controls['page_limit']?.errors?.fieldMessage && (profileForm.controls['page_limit']?.value>0 && pageLimitData))">
                    <i class="icon  icon-Check"></i>
                    <span>{{(profileForm.controls['page_limit']?.value
                        *
                        pageLimitData)}} Products will be collected</span>
                </div>
            </div>
            <div class="input-container" *ngIf="!['product', 'keyword'].includes(profileForm.get('profile_type').value)">
                <div class="infoHeader">
                    <label class="mb-0" for="keyword_search_filter" translate>Search only for<span class='filter-criteria-info'>
                            ({{'Optional' | translate}})</span></label>
                    <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.keywordSearchFilter | translate}}"
                        tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                </div>
                <input type="text" formControlName="keyword_search_filter" autocomplete="off" id="keyword_search_filter"
                    (keydown.enter)="onCriteriaSetKeydown($event,'keyword_search_filter',1)"
                    [placeholder]="'Enter search term' | translate"
                    (blur)="onCriteriaSetKeydown($event,'keyword_search_filter',1)" />
                <div class="input_tag"> <span *ngFor="let skill of criteria.keyword_search_filter; let i = index"
                        class="roundedcorner">
                        {{ skill
                        }}<i class="icon icon-Close2" (click)="dropCriteria(i,'keyword_search_filter')"></i>
                    </span>
                </div>
                <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('keyword_search_filter').value?.length"
                    (click)="onCriteriaSetKeydown($event,'keyword_search_filter',1)"></i>
            </div>
            <div class="input-container frequency-select"> <label for="frequency" translate>Frequency <sup>*</sup></label>
                <ng-select class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                    [loadingText]="'Loading...' | translate" [items]="frequencyList.data"
                    placeholder="{{'Select Frequency' | translate}}" bindLabel="label" [clearable]="false"
                    [ngClass]="{ error: profileForm.controls['frequency'].invalid && (profileForm.controls['frequency'].dirty || profileForm.controls['frequency'].touched || submitted) }"
                    formControlName="frequency" [loading]="frequencyList.isLoaded">
                
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <section class="d-flex align-items-center justify-content-between">
                            <div>{{item.label}}</div>
                            <div class="badge-blue badgeView" *ngIf="item.priority">{{item.priority}}</div>
                        </section>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <section class="d-flex align-items-center justify-content-between">
                            <div>{{item.label}}</div>
                            <div class="badge-blue badgeView" *ngIf="item.priority">{{item.priority}}</div>
                        </section>
                    </ng-template>
                
                </ng-select>
                <div class="errorLable"
                    *ngIf=" profileForm.controls['frequency'].invalid && (profileForm.controls['frequency'].dirty || profileForm.controls['frequency'].touched || submitted) ">
                    <i class="icon icon-Error"></i> 
                    <span *ngIf="profileForm.controls['frequency'].errors.required"translate>Please choose frequency</span>
                </div>
            </div>
            <div class="raw-box d-flex flex-column mb-2-rem" *ngIf="profileType==='keyword'">
                <div class="d-flex gap-8 align-items-center">
                    <label class="checkbox" for="newest_first">
                        <input formControlName="newest_first" type="checkbox" name="checkbox"  id="newest_first">
                        <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                    </label>
                    <span translate class="font-13">Newest First</span>
                </div>
            </div>
            <div class="toggle_title" *ngIf="profileForm.get('profile_type').value==='store'" translate>Tag to a Segment
                <span translate>(Select only 1)</span>
                <div class="toggle_radio_gap mt-2" *ngIf="profileForm.get('profile_type').value==='store'">
                    <ng-container *ngFor="let segment of segmentList">
                        <input type="radio" id="{{segment}}" value="OS" formControlName="tag_segment"
                            [ngClass]="{ active: mappedTag === segment }" />
                        <label for="{{segment}}" (click)="mappedTag=segment">
                            <i class="icon icon-Check"></i>
                            <p translate>{{segmentMap[segment]}}</p>
                        </label>
                    </ng-container>
                </div>
            </div>
            <div class="mb-4" *ngIf="['category'].includes(profileType) && cqPermissions.read_access">
                <div class="toggle_title enable-ai" translate>Enable AI Services<span translate> (in Beta Mode)</span></div>
                <div class="toggle_radio_gap mb-0">
                    <div class="filter-container d-flex m-0" #filterIdentifier>
                        <ng-container *ngFor="let service of mlServices; index as i">
                            <span class="filter-capsule ms-0"
                                [ngbTooltip]="categoryTrackerView!=='official' && actionType==='add'?
                               ('To run product matching, please select a category from SELECT CATEGORY dropdown only.' | translate):null"
                                tooltipClass="info-custom-tooltip  profile-form-tooltip"
                                display="dynamic"
                                *ngIf="profileType==='product' && ['sa', 'cq'].includes(service.code) || profileType==='category' && ['pm'].includes(service.code)"
                                [ngClass]="{ 'active': service.isActive, 'cursor-na':categoryTrackerView!=='official'}" (click)="categoryTrackerView==='official' && onMLServiceSelect(service.code)"> {{ service.name
                                | translate }} </span>
                        </ng-container>
                    </div>
                </div>
            </div>
            <ngb-accordion #myAccordion="ngbAccordion" [closeOthers]="false" [destroyOnHide]='false'>
                <ngb-panel id="panel-1">
                    <ng-template ngbPanelTitle>
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="title-text" translate>Other Settings</p>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="d-flex align-items-center justify-content-between mb-3" *ngIf="profileForm.get('profile_type').value !=='category'">
                            <div class="infoHeader">
                                <label class="mb-0" translate>Start Date</label>
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.startDate | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <div>
                                <label for="start_at" class="calendar ds-box mb-0" [ngClass]="{ error: profileForm.controls['start_at'].invalid && (profileForm.controls['start_at'].dirty || profileForm.controls['start_at'].touched || submitted) }" [class.disabled]="profileForm.controls['start_at'].disabled">
                                    <input  id="start_at" [alwaysShowCalendars]="true" ngxDaterangepickerMd 
                                         [minDate]='minStartDate' [maxDate]="maxStartDate" [opens]="'left'" [isInvalidDate]="checkInvalidDate"
                                        [showClearButton]="true" [singleDatePicker]="true" (change)="onCustomDateUpdate($event,'start_date')" 
                                        readonly [placeholder] ='commonFunctions.getSpecificTranslation("Select date")'
                                        formControlName="start_at"
                                    >
                                    <i class="icon icon-Calendar ngx-daterangepicker-action"></i>
                                </label>
                                <div class="errorLable"
                                *ngIf=" profileForm.controls['start_at'].invalid && (profileForm.controls['start_at'].dirty || profileForm.controls['start_at'].touched || submitted) ">
                                <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['start_at'].errors.required || profileForm.controls['start_at'].errors?.wrong"
                                    translate>Please enter start date.</span>
                            </div>
                        </div>
                        </div>
                        <div class="d-flex align-items-center  justify-content-between  my-4" *ngIf="profileForm.get('profile_type').value !=='category'">
                            <div class="infoHeader">
                                <label class="mb-0" translate>End Date</label>
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.endDate | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <div>
                                <label for="expiry_at" class="calendar ds-box mb-0" [ngClass]="{ error: profileForm.controls['expiry_at'].invalid && (profileForm.controls['expiry_at'].dirty || profileForm.controls['expiry_at'].touched || submitted) }" [class.disabled]="profileForm.controls['expiry_at'].disabled">
                                    <input  id="expiry_at" [alwaysShowCalendars]="true" ngxDaterangepickerMd 
                                        [minDate]='minEndDate' [opens]="'left'" [isInvalidDate]="checkInvalidDate"
                                        [showClearButton]="true" [singleDatePicker]="true" (datesUpdated)="onCustomDateUpdate($event, 'end_date')" [placeholder] ='commonFunctions.getSpecificTranslation("Select date")' readonly formControlName="expiry_at">
                                    <i class="icon icon-Calendar ngx-daterangepicker-action"></i>
                                </label>
                                <div class="errorLable"
                                    *ngIf=" profileForm.controls['expiry_at'].invalid && (profileForm.controls['expiry_at'].dirty || profileForm.controls['expiry_at'].touched || submitted) ">
                                    <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['expiry_at'].errors.required || profileForm.controls['expiry_at'].errors?.wrong"
                                        translate>Please enter End Date</span>
                                </div>
                            </div>
                        </div>
                        <div class="input-container" *ngIf="profileForm.get('profile_type').value ==='product'"> 
                            <div class="infoHeader">
                                <label for="profileName"  class="mb-0" translate>Target Price</label> 
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{targetPriceTooltip | translate}}"
                                tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <input type="number" id="profileName"
                            placeholder="{{'Enter Price' | translate}}" autocomplete="off"
                            formControlName="notification_config" />
                        </div>
                        <div class="select-container">
                            <div class="infoHeader">
                                <label class="mb-0" translate>Brand Collection</label>
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.brandLabel | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <ng-select #selectBrandLabel class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                                [loadingText]="'Loading...' | translate" [items]="labels['brand_label'].data"
                                [addTag]="addNewBrandLabel" [addTagText]="'+ Add New'+' '+'Brand Collection'|titlecase"
                                [placeholder]="'Create or Select a Brand Collection' | translate" bindLabel="attributes.name"
                                [ngClass]="{ error: profileForm.controls['brand_label'].invalid && (profileForm.controls['brand_label'].dirty || profileForm.controls['brand_label'].touched || submitted) }"
                                formControlName="brand_label" [loading]="labels.brand_label.isLoaded" [closeOnSelect]="true"
                                [clearable]="true">
                            </ng-select>
                            <div class="errorLable"
                                *ngIf=" profileForm.controls['brand_label'].invalid && (profileForm.controls['brand_label'].dirty || profileForm.controls['brand_label'].touched || submitted) ">
                                <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['brand_label'].errors.required"
                                    translate>Please
                                    select brand label</span>
                            </div>
                        </div>
                        <div class="select-container">
                            <div class="infoHeader">
                                <label class="mb-0" translate>Product Collection</label>
                                <i class="icon icon-Info-circle" display="dynamic"
                                    ngbTooltip="{{tooltips.productLabel | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>

                            <ng-select #selectProductLabel class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                                [loadingText]="'Loading...' | translate" [items]="labels['product_label'].data"
                                [addTag]="addNewProductLabel" [addTagText]="'+ Add New'+' '+'Product Collection'|titlecase"
                                placeholder="{{'Create or Select a Product Collection' | translate}}" bindLabel="attributes.name"
                                [ngClass]="{ error: profileForm.controls['product_label'].invalid && (profileForm.controls['product_label'].dirty || profileForm.controls['product_label'].touched || submitted) }"
                                formControlName="product_label" [loading]="labels.product_label.isLoaded"> </ng-select>
                            <div class="errorLable"
                                *ngIf=" profileForm.controls['product_label'].invalid && (profileForm.controls['product_label'].dirty || profileForm.controls['product_label'].touched || submitted) ">
                                <i class="icon icon-Error"></i> <span *ngIf="profileForm.controls['product_label'].errors.required"
                                    translate>Please
                                    select SKU label</span>
                            </div>
                        </div>

                        <div class="input-container" *ngIf="!['product'].includes(profileForm.get('profile_type').value)">
                            <div class="infoHeader">
                                <label class="mb-0" for="filter_keywords" translate>Include by Search Term<span
                                        class='filter-criteria-info'> ({{'At most 10 terms' | translate}})</span></label>
                                <i class="icon icon-Info-circle" display="dynamic"
                                    ngbTooltip="{{tooltips.includeByCriteria | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <input type="text" formControlName="filter_keywords" autocomplete="off" id="filter_keywords"
                                (keydown.enter)="onCriteriaSetKeydown($event,'filter_keywords',10)"
                                [placeholder]="'Enter search term' | translate"
                                (blur)="onCriteriaSetKeydown($event,'filter_keywords',10)" />
                            <div class="input_tag"> <span *ngFor="let skill of criteria.filter_keywords; let i = index"
                                    class="roundedcorner"> {{ skill
                                    }}<i class="icon icon-Close2" (click)="dropCriteria(i,'filter_keywords')"></i> </span>
                            </div>
                            <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('filter_keywords').value?.length"
                                (click)="onCriteriaSetKeydown($event,'filter_keywords',10)"></i>
                        </div>
                        <div class="input-container" *ngIf="!['product'].includes(profileForm.get('profile_type').value)">
                            <div class="infoHeader">
                                <label class="mb-0" for="exclude_keywords" translate>Exclude by Search Term<span
                                        class='filter-criteria-info'> ({{'At most 10 terms' | translate}})</span></label>
                                <i class="icon icon-Info-circle" display="dynamic"
                                    ngbTooltip="{{tooltips.excludeByCriteria | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <input type="text" formControlName="exclude_keywords" autocomplete="off" id="exclude_keywords"
                                (keydown.enter)="onCriteriaSetKeydown($event,'exclude_keywords',10)"
                                [placeholder]="'Enter search term' | translate"
                                (blur)="onCriteriaSetKeydown($event,'exclude_keywords',10)" />
                            <div class="input_tag"> <span *ngFor="let skill of criteria.exclude_keywords; let i = index"
                                    class="roundedcorner"> {{ skill
                                    }}<i class="icon icon-Close2" (click)="dropCriteria(i,'exclude_keywords')"></i> </span>
                            </div>
                            <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('exclude_keywords').value?.length"
                                (click)="onCriteriaSetKeydown($event,'exclude_keywords',10)"></i>
                        </div>
                        <!-- New field start -->

                        <div class="input-container" *ngIf="!['product', 'store'].includes(profileForm.get('profile_type').value)">
                            <div class="infoHeader">
                                <label class="mb-0" for="exclude_stores" translate>Exclude by Store<span class='filter-criteria-info'>
                                        ({{'At most 10 stores' | translate}})</span></label>
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.excludeByStore | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <input type="text" formControlName="exclude_stores" autocomplete="off" id="exclude_stores"
                                (keydown.enter)="onCriteriaSetKeydown($event,'exclude_stores',10)" [placeholder]="'Enter store name' | translate"
                                (blur)="onCriteriaSetKeydown($event,'exclude_stores',10)" />
                            <div class="input_tag"> <span *ngFor="let skill of criteria.exclude_stores; let i = index" class="roundedcorner">
                                    {{ skill
                                    }}<i class="icon icon-Close2" (click)="dropCriteria(i,'exclude_stores')"></i> </span>
                            </div>
                            <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('exclude_stores').value?.length"
                                (click)="onCriteriaSetKeydown($event,'exclude_stores',10)"></i>
                        </div>

                        <div class="input-container" *ngIf="!['product', 'brand'].includes(profileForm.get('profile_type').value)">
                            <div class="infoHeader">
                                <label class="mb-0" for="exclude_brands" translate>Exclude by Brand<span class='filter-criteria-info'>
                                        ({{'At most 10 brands' | translate}})</span></label>
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.excludeByBrand | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <input type="text" formControlName="exclude_brands" autocomplete="off" id="exclude_brands"
                                (keydown.enter)="onCriteriaSetKeydown($event,'exclude_brands',10)" [placeholder]="'Enter brand name' | translate"
                                (blur)="onCriteriaSetKeydown($event,'exclude_brands',10)" />
                            <div class="input_tag"> <span *ngFor="let skill of criteria.exclude_brands; let i = index" class="roundedcorner">
                                    {{ skill
                                    }}<i class="icon icon-Close2" (click)="dropCriteria(i,'exclude_brands')"></i>
                                </span>
                            </div>
                            <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('exclude_brands').value?.length"
                                (click)="onCriteriaSetKeydown($event,'exclude_brands',10)"></i>
                        </div>

                        
                        <div class="input-container" *ngIf="!['product', 'category'].includes(profileForm.get('profile_type').value)">
                            <div class="infoHeader">
                                <label class="mb-0" for="exclude_categories" translate>Exclude by Category<span class='filter-criteria-info'>
                                        ({{'At most 10 categories' | translate}})</span></label>
                                <i class="icon icon-Info-circle" display="dynamic" ngbTooltip="{{tooltips.excludeByCategory | translate}}"
                                    tooltipClass="info-custom-tooltip profile-form-tooltip"></i>
                            </div>
                            <input type="text" formControlName="exclude_categories" autocomplete="off" id="exclude_categories"
                                (keydown.enter)="onCriteriaSetKeydown($event,'exclude_categories',10)" [placeholder]="'Enter category name' | translate"
                                (blur)="onCriteriaSetKeydown($event,'exclude_categories',10)" />
                            <div class="input_tag"> <span *ngFor="let skill of criteria.exclude_categories; let i = index" class="roundedcorner">
                                    {{ skill
                                    }}<i class="icon icon-Close2" (click)="dropCriteria(i,'exclude_categories')"></i>
                                </span>
                            </div>
                            <i class="icon icon-Arrow-right enter-state" *ngIf="profileForm.get('exclude_categories').value?.length"
                                (click)="onCriteriaSetKeydown($event,'exclude_categories',10)"></i>
                        </div>

                        <!-- New field end -->
                        <div class="mb-4" *ngIf="['product','category'].includes(profileType) && profileForm.get('profile_type').value !=='category' && cqPermissions.read_access">
                            <div class="toggle_title enable-ai" translate>Enable AI Services<span translate> (in Beta Mode)</span></div>
                            <div class="toggle_radio_gap mb-0">
                                <div class="filter-container d-flex m-0" #filterIdentifier>
                                    <ng-container *ngFor="let service of mlServices; index as i">
                                        <span class="filter-capsule ms-0"
                                        *ngIf="profileType==='product' && ['sa', 'cq'].includes(service.code) || profileType==='category' && ['pm'].includes(service.code)"
                                        [ngClass]="{ 'active': service.isActive}"
                                        (click)="onMLServiceSelect(service.code)"> {{ service.name | translate }} </span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="profile-footer">
                <button class="btn btn-secondary btn-lg" type="button" (click)="onCloseAction()" translate>Cancel
                </button>
                <button class="btn btn-primary btn-lg" type="submit" [disabled]="checkURL"> {{
                    (checkURL ? "Verifying URL..." : actionType==='add' ? "Add Tracker" : "Update Tracker") | translate }} </button>
            </div>
        </div>
    </form>
    <div *ngIf="actionType==='edit' && !profileData && loadingForm"
        class="loading-panel d-flex align-items-center justify-content-center h-75">
        <img ngSrc="../../../../assets/images/spinner.svg" alt=""  height="200" width="200"/>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header p-0">
        <section class="d-flex align-items-center gap-8">
            <h4 class="modal-title" translate>Sample {{profileType | titlecase}} URL</h4>
            <span class="grid-count" *ngIf="sampleURLData.data.length">{{(sampleURLData.data.length |
                numberSuffix: 2)}}</span>
        </section>
        <i class="icon icon-Close2 cursor-pointer" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body mt-2 p-0">
        <div class="grid-wrapper">
            <div class="table-wrapper">
                <ng-scrollbar>
                    <table class="mainTable">
                        <thead>
                            <tr>
                                <th>{{'Marketplace' | translate}}</th>
                                <th>{{'URL (Click on the URL to copy)' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="sampleURLData.data.length">
                            <tr *ngFor="let item of sampleURLData.data;let i=index">
                                <td>
                                    <div class="d-flex align-items-center gap-6">
                                        <app-marketplace-store-logo [storeName]="item.attributes.marketplace_name">
                                        </app-marketplace-store-logo>
                                        <div class="d-inline">{{item.attributes.marketplace_name}}</div>
                                    </div>
                                </td>
                                <td>
                                    <table class="urlTable">
                                        <tbody>
                                            <ng-container *ngIf="item.attributes.examples?.length; else noexample">
                                                <tr *ngFor="let url of item.attributes.examples;let j=index">
                                                    <td>
                                                        <span class="cursor-pointer url" (click)="copyURL(url)">{{url}}</span>
                                                        <span class="copied" *ngIf="copiedObj['copied']===url">{{'Copied' | translate}}!</span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #noexample>
                                                <tr>
                                                    <td>
                                                        <span class="url" translate>No sample URL is available</span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!sampleURLData.data.length" class="nodata d-flex align-items-center justify-content-center">
                        <div *ngIf="!sampleURLData.loading" translate>
                            <div class="no-data-found d-flex align-items-center justify-content-center flex-column">
                                <div class="no-data-text" translate>No sample URL is available</div>
                            </div>
                        </div>
                        <div *ngIf="sampleURLData.loading" class="loading-panel"></div>
                    </div>
                </ng-scrollbar>
            </div>
        </div>
    </div>
</ng-template>