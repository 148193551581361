import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ScorecardService } from "@ds-private-layouts/scorecard/scorecard.service";
import { CommonGridPopUpComponent } from "@ds-shared/common-components/common-grid-pop-up/common-grid-pop-up.component";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { COL } from "@ds-shared/enums/common.enum";
import {
	CommonPopUpModel,
	CurrencyTemplate,
	ImageDownloadObj
} from "@ds-shared/models/common.model";
import moment from "moment";
import { filter, finalize, Subject, takeUntil } from "rxjs";

@Component({
	selector: "app-scorecard-share",
	templateUrl: "./scorecard-share.component.html",
	styleUrls: ["./scorecard-share.component.scss"]
})
export class ScorecardShareComponent implements OnInit, OnDestroy {
	@Input() shareType;
	@Input() currencyDetails: CurrencyTemplate;
	private _scorecardInfo: any;
	get scorecardInfo(): any {
		return this._scorecardInfo;
	}
	@Input() set scorecardInfo(value: any) {
		this._scorecardInfo = value;
		if (this.scorecardInfo) {
		}
	}
	public scorecardShareData = {
		isLoading: false,
		graph: [],
		sliceData: []
	};
	private _dateFilter: any;
	get dateFilter(): any {
		return this._dateFilter;
	}
	@Input() set dateFilter(value: any) {
		this._dateFilter = value;
		if (this.scorecardInfo && this.dateFilter) {
			this.getScorecardShareData();
		}
	}
	public exportDetails = {
		fileName: "",
		path: "scorecard/export-share-of-search",
		params: {},
		optionalParams: {}
	};
	private scorecardName: string = "";
	private $destroy: Subject<boolean> = new Subject();
	@ViewChild(CommonGridPopUpComponent)
	commonGridPopUpComponent: CommonGridPopUpComponent;
	public spliceFilter = null;
	private _optionalParams;
	get optionalParams() {
		return this._optionalParams;
	}
	@Input() set optionalParams(value) {
		this._optionalParams = value;
	}
	private _localFilters;
	get localFilters() {
		return this._localFilters;
	}
	@Input() permittedLocalFilter;
	// @Input() optionalParams;
	@Input() set optionalFilters(value) {
		this._localFilters = value;
		if (this.permittedLocalFilter) {
			if (
				this.permittedLocalFilter.some((item) => {
					return this._localFilters[item];
				})
			) {
				this.getScorecardShareData();
			}
		}
	}

	public imageDownloadObj = {
		isGraph: true,
		graphName: "",
		mainDiv: "",
		legendDiv: ""
	};
	private _miscParams: any = {};
	get miscParams(): any {
		return this._miscParams;
	}
	@Input() set miscParams(value: any) {
		this._miscParams = value;
		if (this.scorecardInfo && Object.keys(this.miscParams).length) {
			this.getScorecardShareData();
		}
	}
	constructor(
		public scorecardService: ScorecardService,
		public commonFunctions: CommonFunctionsService,
		private dataTransferService: DataTransferService
	) {
		this.dataTransferService.onGraphSliceClick$
			.pipe(
				filter(
					(res) =>
						res &&
						res.caller === "scorecard_sos" &&
						res.shareType === this.shareType
				),
				takeUntil(this.$destroy)
			)
			.subscribe((res) => {
				this.openGridView(res);
			});
		// TO get the name of particular scorecard tracker, so we can bind it with share file name
		this.dataTransferService.scorecardBreadcrumb$
			.pipe(
				filter((res) => res),
				takeUntil(this.$destroy)
			)
			.subscribe((res) => {
				this.scorecardName = res;
				this.makeExportFileName();
			});
	}

	ngOnInit(): void {
		this.imageDownloadObj.graphName = `${this.shareType} Share`;
		this.imageDownloadObj.mainDiv = `${this.shareType}Div`;
		this.imageDownloadObj.legendDiv = `${this.shareType}legendDiv`;
		this.getScorecardShareData();
	}
	public getDisplayDate(date) {
		return this.getDisplayDateString(moment(date));
	}
	private getDisplayDateString(momentDate) {
		if (momentDate) return momentDate.format("MMM Do YYYY");
	}

	private getScorecardShareData(spliceFilter?): void {
		this.spliceFilter = spliceFilter;
		this.scorecardShareData.isLoading = true;
		this.scorecardShareData.graph = [];
		this.makeExportFileName();
		this.exportDetails.params = {
			[`${this.scorecardInfo.view}_id`]: this.scorecardInfo.id,
			scorecard_type: this.scorecardInfo.type,
			share: this.shareType,
			base_type: this.shareType === "category" ? "name" : "id"
		};
		const miscFilter = this.commonFunctions.getPayloadFromObject(this.miscParams, "filter");
		this.exportDetails.optionalParams = miscFilter.length ? `${this.optionalParams ? this.optionalParams : ''}&${miscFilter}` : this.optionalParams;
		if (this.permittedLocalFilter) {
			this.permittedLocalFilter.forEach((item) => {
				if (!this.spliceFilter) {
					this.exportDetails.params[this.localFilters?.[item]?.payloadString] =
						this.commonFunctions.getMappedData(
							this.localFilters?.[item]?.selected,
							this.localFilters?.[item]?.meta?.key
						);
				}
			});
		}
		this.exportDetails.params = {
			...this.exportDetails.params,
			start_date: this.dateFilter?.startDate,
			end_date: this.dateFilter?.endDate
		};
		this.exportDetails.params = this.commonFunctions.filterPayload(
			this.exportDetails.params
		);
		this.scorecardService
			.getScorecardShareData(
				this.scorecardInfo.id,
				this.scorecardInfo.type,
				this.shareType,
				this.scorecardInfo.view,
				this.spliceFilter ? null : miscFilter.length ? `${this.optionalParams ? this.optionalParams : ''}&${miscFilter}` : this.optionalParams,
				this.spliceFilter,
				this.dateFilter
			)
			.pipe(
				finalize(() => {
					this.scorecardShareData.isLoading = false;
				}),
				takeUntil(this.$destroy)
			)
			.subscribe((res: any) => {
				this.scorecardShareData.graph = res?.data.map((item, index) => {
					return {
						...item,
						id: item.id !== undefined && item.id !== null ? item.id : index,
						y: item.percentage,
						color: this.commonFunctions.colorArray[index]
							? this.commonFunctions.colorArray[index]
							: "#9B51E0",
						caller: "scorecard_sos",
						shareType: this.shareType,
						marketplace: item.marketplace,
						value: item.value
					};
				});
				this.scorecardShareData.sliceData = JSON.parse(
					JSON.stringify(this.scorecardShareData.graph)
				);
			});
	}
	getExportDateFormat(date) {
		if (!date?.startDate && !date?.endDate) return;
		if (date?.startDate === date?.endDate)
			return date.startDate.split("-").join("");
		return `${date?.startDate.split("-").join("")}_${date?.endDate
			.split("-")
			.join("")}`;
	}
	private makeExportFileName(): void {
		this.exportDetails.fileName = this.commonFunctions.getDynamicExportFileName(
			this.getExportDateFormat(this.dateFilter),
			`${this.commonFunctions.makeTitlecase(this.scorecardInfo.type)} - ${
				this.scorecardName
			} ${this.commonFunctions.makeTitlecase(this.shareType)} Share`
		);
	}
	private openGridView(details) {
		let data: CommonPopUpModel = {
			type: `product-grid`,
			modelName: `Share of Search by ${this.commonFunctions.capitalizeFirstLetter(
				details.shareType
			)}`,
			infoBanner: {
				name: `${details.name}`,
				subName: null,
				value: null,
				subValue: {
					text:
						`${details.y}% ` +
						this.commonFunctions.getSpecificTranslation("of products"),
					class: "badge-dark-new"
				}
			},
			gridInfo: {
				cols: [
					{
						id: 1,
						name: COL.PRODUCT_NAME,
						map: "attributes.name",
						sort: true,
						method: "productImage",
						component: "common-mapping",
						class: "",
						hasRedirect: true,
						isChecked: true,
						meta: { marketplaceLink: true }
					},
					{
						id: 2,
						name: COL.PRICE,
						map: "attributes.price",
						sort: true,
						method: "pricewithDiscountNewView",
						component: "common-mapping",
						class: "font-medium",
						hasRedirect: true,
						isChecked: true,
						style: {
							"th-class": "text-right",
							"td-class": "text-right"
						},
						meta: {
							isOwnCurrencyAvailable: true,
							currencyMap: "attributes.currency.symbol"
						}
					},
					{
						id: 3,
						name: "Rank",
						map: "attributes.product_rank",
						sort: true,
						method: "countView",
						component: "common-mapping",
						class: "font-medium",
						hasRedirect: false,
						isChecked: this.scorecardInfo.view === "profile"
					},
					{
						id: 4,
						name: COL.STORE,
						map: "attributes.store",
						sort: true,
						method: "storewithSegmentView",
						component: "common-mapping",
						class: "ellipsis",
						hasRedirect: true,
						isChecked: true,
						meta: { marketplaceLink: true },
						style: {
							th: {
								"min-width": "9rem",
								"max-width": "11rem"
							},
							td: {
								"min-width": "9rem",
								"max-width": "11rem"
							}
						}
					},
					{
						id: 5,
						name: COL.RETAIL_CHANNEL,
						map: "attributes.marketplace",
						sort: true,
						method: "logoFormat",
						component: "common-mapping",
						class: "",
						hasRedirect: false,
						isChecked: true
					},

					{
						id: 6,
						name: "Rank Updated on",
						map: "attributes.product_rank_last_updated",
						sort: true,
						method: "dateFormat",
						component: "common-mapping",
						class: "",
						hasRedirect: false,
						isChecked: false
					},
					{
						id: 7,
						name: COL.LAST_UPDATED,
						map: "attributes.last_updated",
						sort: true,
						method: "dateFormat",
						component: "common-mapping",
						class: "",
						hasRedirect: false,
						isChecked: true
					},
					{
						id: 8,
						name: "",
						map: "",
						meta: { type: "products" },
						sort: false,
						method: "",
						component: "common-grid-action",
						class: "",
						hasRedirect: false,
						isChecked: true,
						style: {
							"th-class": "text-right",
							"td-class": "text-right"
						}
					}
				],
				path: "share-of-search-popup",
				beforePath: "scorecard",
				params: {
					scorecard_type: this.scorecardInfo.type,
					[`${this.scorecardInfo.view}_id`]: this.scorecardInfo.id,
					share: this.shareType,
					share_id:
						details.id === 0
							? [0]
							: details.harmonised_view
							? this.commonFunctions.getMappedData(details.raw_data, "id")
							: [details.id],
					excluded_id:
						details.id === 0
							? this.getExcludedIdList(this.scorecardShareData.graph)
							: null,
					currency_id: this.currencyDetails.id,
					start_date: this.dateFilter?.startDate,
					end_date: this.dateFilter?.endDate,
					...this.miscParams,
				}
			}
		};
		//To send localfilters in PopUP payload
		this.permittedLocalFilter.forEach((item) => {
			data.gridInfo.params[this.localFilters?.[item]?.payloadString] =
				this.commonFunctions.getMappedData(
					this.localFilters?.[item]?.selected,
					this.localFilters?.[item]?.meta?.key
				);
		});
		if (details.shareType === "category") {
			data.gridInfo.params["share_id"] = null;
			data.gridInfo.params["share_name"] = [
				this.commonFunctions.encodeURi(details.name?.trim())
			];
		}
		if (
			details.id === 0 ||
			(details.name === "Other Categories" && details.shareType === "category")
		) {
			delete data.gridInfo.params["share_id"];
			if (details.shareType === "category") {
				data.gridInfo.params["excluded_name"] =
					this.scorecardShareData.graph.map((item) =>
						this.commonFunctions.encodeURi(item.name.trim())
					);
				delete data.gridInfo.params["share_name"];
			}
		}
	}
	getExcludedIdList(dataList) {
		return dataList.map((item) => {
			return item.id;
		});
	}
	public onPieSplice(event, chart) {
		this.scorecardShareData.graph = JSON.parse(JSON.stringify([]));
		setTimeout(() => {
			this.scorecardShareData.graph = JSON.parse(JSON.stringify(event));
		}, 0);
	}
	ngOnDestroy() {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
