<div class="manage-direct-mapping-container">
	<div class="header-content">
		<i class="icon icon-Caret-left" (click)="onClose()"></i>
		<span translate>Manage Direct Mapping</span>
	</div>
	<div class="selected-product-info">
		<app-product-info
			[info_type]="'customer'"
			*ngIf="marketplaceProductInfo"
			[productDetail]="marketplaceProductInfo"
		></app-product-info>
		<div *ngIf="!marketplaceProductInfo" class="no-data-common">
			<div *ngIf="productInfoPresent" class="no-data-found" translate>
				<div class="img"><i class="icon icon-Search"></i></div>
				<div class="no-data-text" translate>No data present</div>
			</div>
			<div *ngIf="!productInfoPresent" class="loading-panel">
				<img
					ngSrc="/assets/images/spinner.svg"
					alt=""
					height="200"
					width="200"
				/>
			</div>
		</div>
	</div>
	<div class="mapped-product-info">
		<div class="mapped-header">
			<div class="head-content">
				<span class="title" translate>Mapped Product</span>
				<span class="count" *ngIf="marketplaceProductDirectMapping?.length"
					>{{marketplaceProductDirectMapping?.length}}</span
				>
			</div>
			<div
				ngbDropdown
				class="d-inline-block"
				display="dynamic"
				#myDrop="ngbDropdown"
			>
				<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
					><button
						class="btn btn-secondary btn-lg"
						(click)="onDropdownOpen('product')"
						[disabled]="marketplaceProductDirectMapping?.length>=5 || !permissions.write_access"
					>
						+ {{'Add Product' | translate}}
					</button></span
				>
				<div
					ngbDropdownMenu
					aria-labelledby="dropdownBasic"
					class="dashboard-dropdown"
				>
					<h1 class="dashboard-dropdown-head d-flex" translate>
						Add Product Mapping
					</h1>
					<form
						[formGroup]="addProductForm"
						(ngSubmit)="onProductSubmit()"
						(keydown.enter)="$event.preventDefault()"
					>
						<div class="dropdown-content">
							<div
								class="product product-common-title dashboard-dropdown-input"
							>
								<span class="title text-align-right-rtl" translate
									>Select Store</span
								>
								<ng-select
									(close)="onStoreDropdownClose()"
									placeholder="{{'Select Store' | translate}}"
									[notFoundText]="'No Data Found' | translate"
									[loading]="isStoreLoading"
									[loadingText]="'Loading...' | translate"
									[clearable]="false"
									class="select-text-wrap no-clear-all"
									bindLabel="attributes.name"
									formControlName="select_store"
									[items]="storeList"
									(change)="onChange($event,'store')"
									(scrollToEnd)="getStoreList()"
									[searchable]="true"
									(search)="searchData($event,'store')"
								>
								</ng-select>
							</div>
							<div
								class="product product-common-title dashboard-dropdown-input"
							>
								<span class="title text-align-right-rtl" translate
									>Select Product</span
								>
								<ng-select
									(close)="onProductDropdownClose()"
									placeholder="{{'Select Product' | translate}}"
									[notFoundText]="'No Data Found' | translate"
									[loading]="isProductLoading"
									[loadingText]="'Loading...' | translate"
									[clearable]="false"
									class="select-text-wrap no-clear-all"
									bindLabel="attributes.name"
									formControlName="select_product"
									[items]="productList"
									(change)="onChange($event,'product')"
									(scrollToEnd)="getProductList()"
									[searchable]="true"
									(search)="searchData($event,'product')"
								>
								</ng-select>
							</div>
							<div class="action-button">
								<button
									class="btn btn-secondary btn-sm"
									type="button"
									(click)="[myDrop.close()]"
								>
									<span translate> Cancel </span>
								</button>
								<button
									class="btn btn-primary btn-sm"
									type="submit"
									[disabled]="addProductForm.status != 'VALID'"
									(click)="[myDrop.close()]"
								>
									<span translate> Add Product </span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="mapped-content">
			<ng-container
				*ngIf="marketplaceProductDirectMapping?.length===0;else mappedProducts"
			>
				<div class="no-data-common">
					<div
						*ngIf="isMappedProductLoading;else noDataForKeyword"
						class="loading-panel"
					>
						<img
							ngSrc="/assets/images/spinner.svg"
							alt=""
							height="200"
							width="200"
						/>
					</div>
					<ng-template #noDataForKeyword>
						<div class="no-data-found" translate>
							<div class="img"><i class="icon icon-Search"></i></div>
							<div class="no-data-text" translate>No mapped products</div>
						</div>
					</ng-template>
				</div>
			</ng-container>
			<ng-template #mappedProducts>
				<app-product-info
					[info_type]="'competitor'"
					[productDetail]="marketplaceProductDirectMapping"
					(onRefreshAction)="onRefreshAction($event)"
				></app-product-info>
			</ng-template>
		</div>
	</div>
	<div class="mapped-keywords-info">
		<div class="mapped-header">
			<div class="head-content">
				<span class="title" translate>Mapped Keywords</span>
				<span class="count" *ngIf="marketplaceProductMappedKeyword?.length"
					>{{marketplaceProductMappedKeyword?.length}}</span
				>
			</div>
			<div
				ngbDropdown
				class="d-inline-block"
				display="dynamic"
				#myDrop1="ngbDropdown"
			>
				<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
					><button
						class="btn btn-secondary btn-lg"
						(click)="onDropdownOpen('keyword')"
						[disabled]="marketplaceProductMappedKeyword?.length>=3 || !permissions.write_access"
					>
						+ {{'Add Keyword' | translate}}
					</button></span
				>
				<div
					ngbDropdownMenu
					aria-labelledby="dropdownBasic"
					class="dashboard-dropdown keyword-dropdown"
				>
					<h1 class="dashboard-dropdown-head d-flex" translate>
						Add Keyword Mapping
					</h1>
					<form
						[formGroup]="addKeywordForm"
						(ngSubmit)="onKeywordSubmit()"
						(keydown.enter)="$event.preventDefault()"
					>
						<div class="dropdown-content">
							<div
								class="product product-common-title dashboard-dropdown-input"
							>
								<span class="title text-align-right-rtl" translate
									>Select Keyword</span
								>
								<ng-select
									(close)="onKeywordDropdownClose()"
									placeholder="{{'Select Keyword' | translate}}"
									[notFoundText]="'No Data Found' | translate"
									[loadingText]="'Loading...' | translate"
									[clearable]="false"
									class="select-text-wrap no-clear-all"
									bindLabel="name"
									formControlName="select_keyword"
									[items]="keywordList"
									(change)="onChange($event,'keyword')"
									(scrollToEnd)="getKeywordList()"
								>
								</ng-select>
							</div>
							<div class="action-button">
								<button
									class="btn btn-secondary btn-sm"
									type="button"
									(click)="[myDrop1.close()]"
								>
									<span translate> Cancel </span>
								</button>
								<button
									class="btn btn-primary btn-sm"
									[disabled]="addKeywordForm.status != 'VALID'"
									type="submit"
									(click)="[myDrop1.close()]"
								>
									<span translate> Add Keyword </span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="mapped-content">
			<ng-container
				*ngIf="marketplaceProductMappedKeyword?.length===0;else mappedKeywords"
			>
				<div class="no-data-common">
					<div
						*ngIf="isMappedKeywordLoading;else noDataForKeyword"
						class="loading-panel"
					>
						<img
							ngSrc="/assets/images/spinner.svg"
							alt=""
							height="200"
							width="200"
						/>
					</div>
					<ng-template #noDataForKeyword>
						<div class="no-data-found" translate>
							<div class="img"><i class="icon icon-Search"></i></div>
							<div class="no-data-text" translate>No mapped keywords</div>
						</div>
					</ng-template>
				</div>
			</ng-container>
			<ng-template #mappedKeywords>
				<app-product-info
					[info_type]="'keyword'"
					[productDetail]="marketplaceProductMappedKeyword"
					(onRefreshAction)="onRefreshAction($event)"
					[customerProductInfo]="marketplaceProductInfo"
				></app-product-info>
			</ng-template>
		</div>
	</div>
</div>
