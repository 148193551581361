import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { ProfileInformationService } from "@ds-private-layouts/scorecard/profile-information/profile-information.service";
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	takeUntil,
	tap
} from "rxjs/operators";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductSummaryComponent } from "src/app/shared/common-components/scorecard/summary-overlay/product-summary/product-summary.component";
@Component({
	selector: "app-manage-product",
	templateUrl: "./manage-product.component.html",
	styleUrls: ["./manage-product.component.scss"]
})
export class ManageProductComponent implements OnInit, OnDestroy {
	public activeProd: any = [];
	public activeProdLoading: boolean = true;
	public discardedProdLoading: boolean = true;
	public discardedProd: any = [];
	public tempMovedActiveProduct: any = [];
	public tempMovedDiscardedProduct: any = [];
	public actionBody: any = [];
	public pageNumber: number = 1;
	public count = { active: null, discarded: null };
	public searchedActiveProd: UntypedFormControl = new UntypedFormControl("");
	public searchedDiscardedProd: UntypedFormControl = new UntypedFormControl("");
	public activeProductSubscription: Subscription;
	public discardedProductSubscription: Subscription;
	public profileDataSubscription: Subscription;
	public moveProductSubscription: Subscription;
	public sort = "";
	public profileData: any = { profileId: null, isCustomer: null };
	public profileId: any;
	public dashboardCurrencySubscription: Subscription;
	public dashboardCurrencyData: any;
	public pageSize: number = 10;
	public activePageNo: number = 1;
	public discardedPageNo: number = 1;
	public $destroy: Subject<boolean> = new Subject();
	public sortActiveList: any;
	public sortDeactiveList: any;
	public mouseActionType: any;
	public sortDirection: any;
	public permissions: RolePermissions = new RolePermissions();
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private profileInformationService: ProfileInformationService,
		private notificationService: NotificationService,
		public renderer: Renderer2,
		private dataTransferService: DataTransferService,
		public commonFunctions: CommonFunctionsService,
		public accessMatrixService: AccessMatrixService,
		private ngbModalService: NgbModal
	) {
		this.permissions = this.accessMatrixService.getPermissionByModule(
			AccessMatrix.Scorecard
		);
		this.activeProductSearchInit();
		this.discardedProductSearchInit();
	}

	activeProductSearchInit() {
		this.searchedActiveProd?.valueChanges
			.pipe(
				tap((term) => {
					this.activeProdLoading = true;
					this.activePageNo = 1;
					this.activeProd = [];
				}),
				debounceTime(500),
				distinctUntilChanged(),
				takeUntil(this.$destroy)
			)
			.subscribe(() => {
				this.getActiveProducts();
			});
	}

	discardedProductSearchInit() {
		this.searchedDiscardedProd?.valueChanges
			.pipe(
				tap((term) => {
					this.discardedProdLoading = true;
					this.discardedProd = [];
					this.discardedPageNo = 1;
				}),
				debounceTime(500),
				distinctUntilChanged(),
				takeUntil(this.$destroy)
			)
			.subscribe(() => {
				this.getDiscardedProducts();
			});
	}

	ngOnInit(): void {
		this.dashboardCurrencySubscription?.unsubscribe();
		this.dashboardCurrencySubscription =
			this.dataTransferService.dashboardCurrencyData$.subscribe((data: any) => {
				this.dashboardCurrencyData = data;
			});
		this.profileDataSubscription = this.route.queryParams.subscribe(
			(e: any) => {
				if (e?.profileId) {
					this.profileData.profileId = e?.profileId;
				}
				if (e?.is_customer == "true") this.profileData.isCustomer = true;
				else this.profileData.isCustomer = false;
				this.getActiveProducts();
				this.getDiscardedProducts();
				this.getSortList();
			}
		);
	}
	getActiveProducts() {
		if (this.activeProductSubscription)
			this.activeProductSubscription.unsubscribe();
		if (this.activePageNo != null) {
			this.activeProductSubscription = this.profileInformationService
				.getActiveProducts(
					this.profileData.profileId,
					this.profileData.isCustomer,
					this.searchedActiveProd.value?.trim(),
					this.sort,
					this.pageSize,
					this.activePageNo,
					null
				)
				.subscribe(
					(res: any) => {
						if (res) {
							if (res["context_code"] == 1000) {
								this.activeProdLoading = false;
								if (this.activeProd)
									for (const data of res["data"])
										this.activeProd = [...this.activeProd, data];
								else this.activeProd = res["data"];
								this.activePageNo = res["meta"]["next_page"];
								this.count.active = res?.meta?.total_count;
							}
						}
					},
					(err) => {
						this.activeProdLoading = false;
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
						this.activeProd = null;
						this.count.active = null;
					}
				);
		}
	}

	getDiscardedProducts() {
		if (this.discardedProductSubscription)
			this.discardedProductSubscription.unsubscribe();
		if (this.discardedPageNo != null) {
			this.discardedProductSubscription = this.profileInformationService
				.getDiscardedProducts(
					this.profileData.profileId,
					this.profileData.isCustomer,
					this.searchedDiscardedProd.value?.trim(),
					this.sort,
					this.pageSize,
					this.discardedPageNo,
					null
				)
				.subscribe(
					(res: any) => {
						if (res) {
							if (res["context_code"] == 1000) {
								if (this.discardedProd)
									for (const data of res["data"])
										this.discardedProd = [...this.discardedProd, data];
								else this.discardedProd = res["data"];
								this.discardedPageNo = res["meta"]["next_page"];
								this.discardedProdLoading = false;
								this.count.discarded = res?.meta?.total_count;
							}
						}
					},
					(err) => {
						this.discardedProdLoading = false;
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
						this.discardedProd = null;
						this.count.discarded = null;
					}
				);
		}
	}

	checkActiveProd(eve, i, prod) {
		if ("isChecked" in this.activeProd[i]) {
			this.activeProd[i].isChecked = !this.activeProd[i].isChecked;
		} else {
			this.activeProd[i].isChecked = true;
		}
		if (this.activeProd[i].isChecked == true) {
			this.tempMovedActiveProduct.push(prod);
		} else {
			this.tempMovedActiveProduct.forEach((element, key) => {
				if (element.id == this.activeProd[i].id) {
					this.tempMovedActiveProduct.splice(key, 1);
				}
			});
		}
	}

	checkDiscardedProd(eve, i, prod) {
		if ("isChecked" in this.discardedProd[i]) {
			this.discardedProd[i].isChecked = !this.discardedProd[i].isChecked;
		} else {
			this.discardedProd[i].isChecked = true;
		}
		if (this.discardedProd[i].isChecked == true) {
			this.tempMovedDiscardedProduct.push(prod);
		} else {
			this.tempMovedDiscardedProduct.forEach((element, key) => {
				if (element.id == this.discardedProd[i].id) {
					this.tempMovedDiscardedProduct.splice(key, 1);
				}
			});
		}
	}

	onMoveProduct(type) {
		this.searchedActiveProd.reset("", { emitEvent: false });
		this.searchedDiscardedProd.reset("", { emitEvent: false });
		this.sort = null;
		this.actionBody = [];
		if (type == "active") {
			this.tempMovedActiveProduct.forEach((element, key) => {
				this.actionBody;
				const obj = {
					is_customer: this.profileData.isCustomer,
					product_id: element.id,
					is_discarded: true,
					id: this.profileData.profileId
				};
				this.actionBody.push(obj);
			});
		}
		if (type == "discarded") {
			this.tempMovedDiscardedProduct.forEach((element, key) => {
				this.actionBody;
				const obj = {
					is_customer: this.profileData.isCustomer,
					product_id: element.id,
					is_discarded: false,
					id: this.profileData.profileId
				};
				this.actionBody.push(obj);
			});
		} else if (type == "active all") {
			this.activeProd.forEach((element, key) => {
				this.actionBody;
				const obj = {
					is_customer: this.profileData.isCustomer,
					product_id: element.id,
					is_discarded: true,
					id: this.profileData.profileId
				};
				this.actionBody.push(obj);
			});
		} else if (type == "discarded all") {
			this.discardedProd.forEach((element, key) => {
				this.actionBody;
				const obj = {
					is_customer: this.profileData.isCustomer,
					product_id: element.id,
					is_discarded: false,
					id: this.profileData.profileId
				};
				this.actionBody.push(obj);
			});
		}
		this.activeProdLoading = true;
		this.discardedProdLoading = true;
		if (this.moveProductSubscription)
			this.moveProductSubscription.unsubscribe();
		this.moveProductSubscription = this.profileInformationService
			.postActiveToAndFroDiscardProducts(null, null, this.actionBody)
			.subscribe(
				(response: any) => {
					if (response) {
						if (response["context_code"] == 1000) {
							this.notificationService.setMessage(
								response["context_code"],
								response["summary"]
							);
							this.resetProducts();
						}
					}
				},
				(err) => {
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
					this.resetProducts();
				}
			);
	}

	resetProducts() {
		this.activeProd = null;
		this.discardedProd = null;
		this.tempMovedActiveProduct = [];
		this.tempMovedDiscardedProduct = [];
		this.actionBody = [];
		this.activePageNo = 1;
		this.discardedPageNo = 1;
		this.getActiveProducts();
		this.getDiscardedProducts();
	}

	onActiveScroll() {
		this.getActiveProducts();
	}

	onDiscardedScroll() {
		this.getDiscardedProducts();
	}

	getSortList() {
		this.sortActiveList = {
			name: true,
			price: true,
			discount: true
		};
		this.sortDeactiveList = {
			name: true,
			price: true,
			discount: true
		};
	}

	sortInGrid(sortType: any) {
		this.mouseActionType = sortType;
		const sortKey = this.mouseActionType.split("-")[0];
		const type = this.mouseActionType.split("-")[1];
		if (type == "active") {
			this.sort = this.sortActiveList[sortKey] ? sortKey : `-${sortKey}`;
			this.sortActiveList[sortKey] = !this.sortActiveList[sortKey];
			this.sortDirection = this.sortActiveList[sortKey]
				? "icon-Arrow-down"
				: "icon-Arrow-up";
			this.activeProdLoading = true;
			this.activeProd = [];
			this.activePageNo = 1;
			this.getActiveProducts();
		} else if (type == "deactive") {
			this.sort = this.sortDeactiveList[sortKey] ? sortKey : `-${sortKey}`;
			this.sortDeactiveList[sortKey] = !this.sortDeactiveList[sortKey];
			this.sortDirection = this.sortDeactiveList[sortKey]
				? "icon-Arrow-down"
				: "icon-Arrow-up";
			this.discardedProdLoading = true;
			this.discardedProd = [];
			this.discardedPageNo = 1;
			this.getDiscardedProducts();
		}
	}
	mouseAction(actionValue: any) {
		this.mouseActionType = actionValue;
		if (this.mouseActionType != "") {
			const sortKey = this.mouseActionType.split("-")[0];
			const type = this.mouseActionType.split("-")[1];
			if (type == "active") {
				this.sortDirection = this.sortActiveList[sortKey]
					? "icon-Arrow-down"
					: "icon-Arrow-up";
			} else if (type == "deactive") {
				this.sortDirection = this.sortDeactiveList[sortKey]
					? "icon-Arrow-down"
					: "icon-Arrow-up";
			}
		}
	}

	openDirectMapping(productId: any) {
		if (this.commonFunctions.getURLSegments()[0] === "build") {
			this.router.navigate(
				[
					`${this.commonFunctions.getURLSegments()[0]}/${this.commonFunctions.getURLSegments()[1]}/${this.commonFunctions.getURLSegments()[2]}/manage-mappings`
				],
				{
					queryParams: {
						profileId: null,
						is_customer: null,
						customerProductId: productId
					},
					queryParamsHandling: "merge"
				}
			);
		} else {
			this.router.navigate(["/scorecard/profile/manage-mappings"], {
				queryParams: {
					profileId: null,
					is_customer: null,
					customerProductId: productId
				},
				queryParamsHandling: "merge"
			});
		}
	}

	openProductSummaryOverlay(id) {
		const componentRef = this.ngbModalService.open(ProductSummaryComponent, {
			centered: true
		});
		componentRef.componentInstance.productId = id;
	}

	ngOnDestroy() {
		if (this.moveProductSubscription)
			this.moveProductSubscription.unsubscribe();
		if (this.activeProductSubscription)
			this.activeProductSubscription.unsubscribe();
		if (this.discardedProductSubscription)
			this.discardedProductSubscription.unsubscribe();
		this.dashboardCurrencySubscription?.unsubscribe();
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
