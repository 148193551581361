import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from "@angular/core";
import { DashboardService } from "@ds-private-layouts/dashboard/dashboard.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import {
	DashboardFilterParams,
	MarketplaceModel,
	SingleItemModel
} from "@ds-shared/models/dashboard.model";
import { SelloutFilterParams } from "@ds-shared/models/sellout.model";
import { Subject, finalize, takeUntil, Subscription } from "rxjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LocaleConfig } from "ngx-daterangepicker-material";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslationService } from "@ds-common-services/utility-services/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { Modules } from "@ds-shared/enums/modules.enum";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { COMMONTEXT } from "@ds-shared/enums/common.enum";
import { MarketShareTimelineType } from "@ds-shared/enums/sales.enum";

enum FilterType {
	"timeline" = "timeline",
	"marketplace" = "marketplace",
	"market_share" = "market_share"
}

type SelectedDate = {
	startDate: Dayjs;
	endDate: Dayjs;
	label?: string;
};

@Component({
	selector: "app-timeline-filters",
	templateUrl: "./timeline-filters.component.html",
	styleUrls: ["./timeline-filters.component.scss"]
})
export class TimelineFiltersComponent implements OnInit {
	@Input() showTimeFilter: boolean = true;
	@Input() public isDirectChild: boolean = true;
	@Input() public module: string;
	@Input() public showFilter: {
		showTimelineText?: boolean;
		marketplace: boolean;
		timeline: boolean;
		customRange: boolean;
		kpiToggle?: boolean;
		showCurrency: boolean;
		showMarketShare?: boolean;
	} = {
		showTimelineText: true,
		marketplace: true,
		timeline: true,
		customRange: false,
		kpiToggle: false,
		showCurrency: true,
		showMarketShare: false
	};
	@Input() public dataEmit = true;
	@Output() private selectedFilterData = new EventEmitter();
	public FilterType = FilterType;
	public response: any;
	public isTheCalendarForMarketShare: boolean = false;
	public isCalendarRangeClicked: boolean = true;
	private isDoneActionPerformed: boolean = false;
	public timelineConst = this.commonFunctions.getTimeLine();
	public timeLineList: {
		id: number;
		label: string;
		startDate?: any;
		endDate?: any;
		showOnlyFor: string[];
		code: string;
	}[] = [
		// {
		// 	id: 1,
		// 	label: "Today",
		// 	code: 'today',
		// 	startDate: timelineConst['today']['startDate'],
		// 	endDate: timelineConst['today']['endDate'],
		// 	showOnlyFor: [Modules.dashboard, Modules.pricing, Modules.availability, Modules["content-quality"]]
		// },
		{
			id: 2,
			label: "Yesterday",
			code: "yesterday",
			startDate: this.timelineConst["yesterday"]["startDate"],
			endDate: this.timelineConst["yesterday"]["endDate"],
			showOnlyFor: [
				Modules.dashboard,
				Modules.pricing,
				Modules.availability,
				Modules["content-quality"]
			]
		},
		{
			id: 3,
			label: "Current Week",
			code: "current_week",
			startDate: this.timelineConst["current_week"]["startDate"],
			endDate: this.timelineConst["current_week"]["endDate"],
			showOnlyFor: [
				Modules.dashboard,
				Modules.pricing,
				Modules.availability,
				Modules["content-quality"]
			]
		},
		{
			id: 4,
			label: "Previous Week",
			code: "previous_week",
			startDate: this.timelineConst["previous_week"]["startDate"],
			endDate: this.timelineConst["previous_week"]["endDate"],
			showOnlyFor: [
				Modules.dashboard,
				Modules.pricing,
				Modules.availability,
				Modules["content-quality"]
			]
		},
		{
			id: 5,
			label: "Current Month",
			code: "current_month",
			startDate: this.timelineConst["current_month"]["startDate"],
			endDate: this.timelineConst["current_month"]["endDate"],
			showOnlyFor: [
				Modules.dashboard,
				Modules.pricing,
				Modules.availability,
				Modules["content-quality"]
			]
		},
		{
			id: 6,
			label: "Previous Month",
			code: "previous_month",
			startDate: this.timelineConst["previous_month"]["startDate"],
			endDate: this.timelineConst["previous_month"]["endDate"],
			showOnlyFor: [
				Modules.dashboard,
				Modules.pricing,
				Modules.availability,
				Modules["content-quality"]
			]
		},
		{
			id: 7,
			label: "Monthly Trend",
			code: "last_30_days",
			startDate: this.timelineConst["last_30_days"]["startDate"],
			endDate: this.timelineConst["last_90_days"]["endDate"],
			showOnlyFor: []
		},
		{
			id: 8,
			label: "Quarterly Trend",
			code: "last_90_days",
			startDate: this.timelineConst["last_90_days"]["startDate"],
			endDate: this.timelineConst["last_90_days"]["endDate"],
			showOnlyFor: [Modules.sales, Modules["content-quality"]]
		}
	];
	dropdownOpenStatus = {
		viewBy: false,
		retail: false,
		currency: false,
		marketShare: false
	};
	private destroy$: Subject<boolean> = new Subject();
	public selectedDate: any = {
		startDate: this.timelineConst["current_month"]["startDate"],
		endDate: this.timelineConst["current_month"]["endDate"]
	};
	public customDate: any = { ...this.selectedDate };
	calendarLocale: LocaleConfig;
	public minDate: Dayjs =
		this.timelineConst["last_90_days"]["startDate"];
	public maxDate: Dayjs = this.timelineConst["last_90_days"]["endDate"];
	public defaultMetricView: string = "sales";
	isFirstTime: boolean = true;
	ranges: any = {
		// Today: [moment(), moment()],
		Yesterday: [
			this.timelineConst["yesterday"]["startDate"],
			this.timelineConst["yesterday"]["endDate"]
		],
		"Last 7 Days": [
			this.timelineConst["current_week"]["startDate"],
			this.timelineConst["current_week"]["endDate"]
		],
		"Last 30 Days": [
			this.timelineConst["last_30_days"]["startDate"],
			this.timelineConst["last_30_days"]["endDate"]
		],
		"This Month": [
			this.timelineConst["this_month"]["startDate"],
			this.timelineConst["this_month"]["endDate"]
		],
		"Last 60 Days": [
			this.timelineConst["last_60_days"]["startDate"],
			this.timelineConst["last_60_days"]["endDate"]
		],
		"Last 90 Days": [
			this.timelineConst["last_90_days"]["startDate"],
			this.timelineConst["last_90_days"]["endDate"]
		]
	};
	public currency = {
		key: "currency",
		multiple: false,
		list: [],
		selected: null,
		dependencyStatus: null,
		placeholder: "Currency",
		loading: false,
		search: null,
		path: "currency",
		query: [],
		clear: false,
		closeOnSelect: true,
		disabled: false
	};
	public marketplace: {
		list: SingleItemModel[];
		selectedMarketplace: SingleItemModel[];
		placeholder: string;
		pageNumber: number;
		searchMarketplace: string;
		isLoading: boolean;
		marketplaceInitialList: SingleItemModel[];
		initialMarketplacePageNumber: number;
	} = {
		list: [],
		selectedMarketplace: [] as SingleItemModel[],
		placeholder: `${COMMONTEXT.RETAIL_CHANNEL}s`,
		pageNumber: 1,
		searchMarketplace: "",
		isLoading: true,
		marketplaceInitialList: [],
		initialMarketplacePageNumber: 1
	};
	public selectedTimeline: {
		id: number;
		label: string;
		startDate?: any;
		endDate?: any;
		showOnlyFor: string[];
		code: string;
	} = this.timeLineList[3];
	public cleargetCurrencyListCall: Subscription;
	public clearGetMarketplaceListCall: Subscription;
	public filterData: any;
	public moduleName: string = "";
	@Input() showRanges: boolean = true;
	@Input() hideSelectedDateText = false;
	private marketPlaceTimeline: any = {};
	constructor(
		private dashboardService: DashboardService,
		private localStorageService: LocalStorageService,
		public commonFunctions: CommonFunctionsService,
		public dataTransferService: DataTransferService,
		public route: ActivatedRoute,
		private modalService: NgbModal,
		private translationService: TranslationService,
		public translate: TranslateService,
		public router: Router,
		private mixpanelService: MixpanelService,
		private globalService: GlobalService,
		private elementRef: ElementRef,
		private notificationService: NotificationService
	) {
		dayjs.extend(isoWeek)
		dayjs.extend(utc)
		dayjs.extend(customParseFormat)
		dayjs.extend(localizedFormat)
		this.moduleName = this.commonFunctions.getModuleName();
		this.calendarLocale = {
			applyLabel: this.gt("DONE"),
			clearLabel: this.gt("CANCEL"),
			daysOfWeek: [
				this.gt("Su"),
				this.gt("Mo"),
				this.gt("Tu"),
				this.gt("We"),
				this.gt("Th"),
				this.gt("Fr"),
				this.gt("Sa")
			],
			monthNames: [
				this.gt("January"),
				this.gt("February"),
				this.gt("March"),
				this.gt("April"),
				this.gt("May"),
				this.gt("June"),
				this.gt("July"),
				this.gt("August"),
				this.gt("September"),
				this.gt("October"),
				this.gt("November"),
				this.gt("December")
			],
			customRangeLabel: this.gt("Custom Range")
		};
	}

	ngOnInit(): void {
		// Check if User is Panasonic
		const customerName = this.commonFunctions
			.getSavedUserData()
			?.customer?.attributes?.name?.toLocaleLowerCase();
		if (customerName === "panasonic")
			this.selectedTimeline = this.timeLineList[1];
		this.filterData = this.localStorageService.get("filterDataMP");
		if (this.filterData && this.filterData.name === "marketplace")
			this.patchMarketplaceFilter();
		this.dataTransferService.isSecondaryScorecardFilterSet$
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				if (res) {
					this.selectedDate = {
						startDate: dayjs().subtract(1, "days"),
						endDate: dayjs().subtract(1, "days")
					};
					this.customDate = { ...this.selectedDate };
				}
			});
		this.timeLineList = this.timeLineList.filter((item) =>
			item.showOnlyFor.includes(this.moduleName)
		);
		if (this.moduleName === Modules.sales) {
			this.selectedTimeline = this.timeLineList[0];
		}
		if (this.moduleName === Modules.scorecard) {
			this.selectedDate = {
				startDate: dayjs().subtract(1, "days"),
				endDate: dayjs().subtract(1, "days")
			};
			this.customDate = { ...this.selectedDate };
		}
		if (
			this.localStorageService.get(
				this.localStorageService.globalFilterStorageKey
			)
		)
			this.setSelectedFilter();
		if (this.dataEmit) {
			if (!(this.filterData && this.filterData.name === "marketplace"))
				this.fireSelectedFilter();
		}
		if (
			(this.moduleName === Modules.sales && !this.checkReqSubmodules()) ||
			(this.moduleName === Modules.availability &&
				this.route["_routerState"]["snapshot"]["url"].includes(
					"share-of-assortments"
				))
		) {
			this.ranges = Object.keys(this.ranges).reduce((acc, key) => {
				if (key !== "Today") {
					acc[key] = this.ranges[key];
				}
				return acc;
			}, {});
		}
		this.isTheCalendarForMarketShare = this.router.url.includes("market-share");
		if (this.isTheCalendarForMarketShare)
			this.maxDate = dayjs().subtract(1, "d");
	}
	ngAfterViewInit(): void {
		const customRangeButton = this.elementRef.nativeElement.querySelector(
			".ranges li:last-of-type button"
		);
		customRangeButton?.addEventListener("click", () => {
			this.isCalendarRangeClicked = false;
			// this.notificationService.setMessage(1200, this.commonFunctions.getSpecificTranslation("Please note that while using custom date ranges visualizations won't be clickable, and access to product tabs will be restricted."));
			const label = document.querySelector("label");
			label.classList.add("showCalendar");
			label.classList.remove("marketShareCalendar");
			this.fireSelectedFilter();
		});
		const otherRangesButton = document.querySelectorAll(".ranges ul li");
		otherRangesButton.forEach((ele: HTMLElement, i: number) => {
			if (i === otherRangesButton.length - 1) return;
			ele.addEventListener("click", () => {
				this.closeCustomCalender();
			});
		});
	}

	private openCustomCalendar() {
		const label = document.querySelector("label");
		label.classList.add("showCalendar");
		label.classList.remove("marketShareCalendar");
	}

	private closeCustomCalender() {
		const label = document.querySelector("label");
		if (label.classList.contains("marketShareCalendar")) return;
		else {
			label.classList.add("marketShareCalendar");
			label.classList.remove("showCalendar");
		}
	}

	public onCalenderIconClick() {
		const storedCalenderObj = this.localStorageService.get(
			this.localStorageService.calendarTimelineCustomRangeStorageKey
		);
		if (!!storedCalenderObj.timeline.label) {
			this.closeCustomCalender();
		} else {
			this.openCustomCalendar();
		}
	}

	public refreshPage() {
		const URL = this.router.url;
		this.router
			.navigateByUrl("/user-management/account-overview", {
				skipLocationChange: true
			})
			.then(() => {
				this.router.navigate([URL]).then(() => {});
			});
	}
	private setSelectedFilter() {
		this.timeLineList.forEach((v, k) => {
			if (
				v.label ===
				this.localStorageService.get(
					this.localStorageService.globalFilterStorageKey
				)?.[this.moduleName === Modules.sales ? "salesTimeline" : "timeline"]
					?.label
			)
				this.selectedTimeline = v;
		});
		if (!(this.filterData && this.filterData.name === "marketplace"))
			this.marketplace.selectedMarketplace = this.localStorageService.get(
				this.localStorageService.globalFilterStorageKey
			)?.marketplace;
		// Retrieve custom calendar range dates from storage
		if (this.moduleName !== Modules.scorecard) {
			const customRangeValue = this.localStorageService.get(
				this.localStorageService.calendarTimelineCustomRangeStorageKey
			);
			if (this.showFilter.customRange && customRangeValue) {
				const timeline = customRangeValue.timeline;
				this.selectedDate = {
					startDate: dayjs(timeline.startDate),
					endDate: dayjs(timeline.endDate)
				};
			}
			if (this.moduleName === Modules.sales) {
				const customRangeValue = this.localStorageService.get(
					this.localStorageService.calendarTimelineCustomRangeStorageKey
				);
				this.selectedDate = {
					startDate: dayjs().subtract(30, "days"),
					endDate: dayjs().subtract(1, "days"),
					label: "Last 30 Days"
				};
				this.customDate = { ...this.selectedDate };
			}
		}
	}

	private fireSelectedFilter(filterType?): void {
		if (this.isDirectChild) {
			if (!this.showFilter.customRange) {
				this.selectedFilterData.emit({
					timeline: this.selectedTimeline.code,
					marketplace: this.marketplace.selectedMarketplace
				});
				this.dataTransferService.sendDirectMappingChange({
					...this.dataTransferService.selloutReportFilter,
					timeline: {
						...this.selectedTimeline,
						startDate: this.getNumberDate(this.selectedTimeline.startDate),
						endDate: this.getNumberDate(this.selectedTimeline.endDate)
					},
					marketplace: this.marketplace.selectedMarketplace,
					isCustomRangeClicked: this.isCalendarRangeClicked
				});
			} else {
				this.selectedFilterData.emit({
					timeline: this.selectedDate?.label
						.toLocaleLowerCase()
						.split(" ")
						.join("_"),
					marketplace: this.marketplace.selectedMarketplace
				});
			}
			const getTimelineStorageValues = this.localStorageService.get(
				this.localStorageService.globalFilterStorageKey
			)?.timeline;
			const getSalesTimelineStorageValues = this.localStorageService.get(
				this.localStorageService.globalFilterStorageKey
			)?.salesTimeline;
			this.localStorageService.set(
				this.localStorageService.globalFilterStorageKey,
				{
					timeline:
						this.moduleName === Modules.sales
							? getTimelineStorageValues
							: this.selectedTimeline,
					marketplace: this.marketplace.selectedMarketplace,
					salesTimeline:
						this.moduleName === Modules.sales
							? this.selectedTimeline
							: getSalesTimelineStorageValues
				}
			);
			this.marketPlaceTimeline = this.selectedTimeline;
			this.globalService.getMarketplaceConfiguration();
			this.marketPlaceTimeline = this.selectedTimeline;
		} else {
			if (!this.showFilter.customRange) {
				this.dataTransferService.sendDirectMappingChange({
					...this.dataTransferService.selloutReportFilter,
					timeline: {
						...this.selectedTimeline,
						startDate: this.getNumberDate(this.selectedTimeline.startDate),
						endDate: this.getNumberDate(this.selectedTimeline.endDate)
					},
					marketplace: this.marketplace.selectedMarketplace,
					isCustomRangeClicked: this.isCalendarRangeClicked
				});
			} else {
				let copyOfSelectedDate: SelectedDate = { ...this.selectedDate };
				for (let range of Object.keys(this.ranges)) {
					const [start, end] = this.ranges[range];
					const formattedStart = dayjs(start).format("DD/MM/YYYY");
					const formattedEnd = dayjs(end).format("DD/MM/YYYY");
					if (
						formattedStart ===
						dayjs(this.selectedDate.startDate).format("DD/MM/YYYY") &&
						formattedEnd ===
						dayjs(this.selectedDate.endDate).format("DD/MM/YYYY")
					) {
						copyOfSelectedDate["label"] = range;
						break;
					}
				}
				this.dataTransferService.sendDirectMappingChange({
					...this.dataTransferService.selloutReportFilter,
					timeline: {
						...copyOfSelectedDate,
						startDate: copyOfSelectedDate.label
							? this.getNumberDate(this.ranges[copyOfSelectedDate.label][0])
							: this.getNumberDate(dayjs(copyOfSelectedDate.startDate)),
						endDate: copyOfSelectedDate.label
							? this.getNumberDate(this.ranges[copyOfSelectedDate.label][1])
							: this.getNumberDate(dayjs(copyOfSelectedDate.endDate))
					},
					marketplace: this.marketplace.selectedMarketplace,
					isCustomRangeClicked: this.isCalendarRangeClicked,
					isDoneActionPerformed: this.isDoneActionPerformed
				});

				// Storing Calendar Timeline in localStorage
				this.localStorageService.set(
					this.localStorageService.calendarTimelineCustomRangeStorageKey,
					{
						timeline: copyOfSelectedDate
					}
				);
				this.marketPlaceTimeline = copyOfSelectedDate;
			}
			if (this.moduleName != Modules.sales) {
				this.localStorageService.set(
					this.localStorageService.globalFilterStorageKey,
					{
						...this.dataTransferService.selloutReportFilter,
						timeline: {
							...this.selectedTimeline,
							startDate: this.getNumberDate(this.selectedTimeline.startDate),
							endDate: this.getNumberDate(this.selectedTimeline.endDate)
						},
						marketplace: this.marketplace.selectedMarketplace
					}
				);
			}
		}
		filterType !== "marketplace" &&
			this.showFilter.marketplace &&
			this.getMarketplaceList(true);
	}
	public getMarketplaceList(isReset?): void {
		if (this.route["_routerState"]["snapshot"]["url"] === "/content-quality") {
			this.marketPlaceTimeline["label"] = "Last 90 Days";
		}
		if (
			this.route["_routerState"]["snapshot"]["url"] ===
			"/pricing/price-monitoring"
		) {
			this.marketPlaceTimeline = null;
		}
		if (!isReset) {
			if (!this.marketplace.pageNumber) return;
		}
		this.marketplace.isLoading = true;
		this.clearGetMarketplaceListCall?.unsubscribe();
		this.clearGetMarketplaceListCall = this.dashboardService
			.getMarketplaceList(
				this.marketplace.pageNumber || 1,
				this.marketplace.searchMarketplace,
				this.router.url.includes("scorecard")
					? ""
					: this.commonFunctions.currencySelected?.id,
				this.router.url.split("/").slice(1).join("-"),
				null,
				this.marketPlaceTimeline?.label
					? `&filter[timeline]=${
							MarketShareTimelineType[this.marketPlaceTimeline.label]
					  }`
					: `&filter[start_date]=${this.getNumberDate(
						dayjs(this.marketPlaceTimeline?.startDate)
					  )}&filter[end_date]=${this.getNumberDate(
						dayjs(this.marketPlaceTimeline?.endDate)
					  )}`
			)
			.pipe(
				finalize(() => (this.marketplace.isLoading = false)),
				takeUntil(this.destroy$)
			)
			.subscribe(
				(res: MarketplaceModel) => {
					if (!res) return;
					this.marketplace.list = [...res?.data];
					this.marketplace.pageNumber = res.meta.next_page;

					/* to store the initial list,so when the user selects value from 2nd or more than that page number and closes the select box,
		 so the select box will be initialized with first page values */
					if (
						res.meta.current_page === 1 &&
						!this.marketplace.marketplaceInitialList.length
					) {
						this.marketplace.marketplaceInitialList = JSON.parse(
							JSON.stringify(this.marketplace.list)
						);
						this.marketplace.initialMarketplacePageNumber =
							this.marketplace.pageNumber;
					}
				},
				() => {
					this.marketplace.list = [];
					this.marketplace.marketplaceInitialList = [];
					this.marketplace.pageNumber = 1;
					this.marketplace.initialMarketplacePageNumber = 1;
				}
			);
	}
	public resetMarketplace(): void {
		this.marketplace.list = [];
		this.marketplace.selectedMarketplace = [];
		this.marketplace.pageNumber = 1;
		this.marketplace.searchMarketplace = "";
	}
	// On close marketplace list, it will reassign the initial values
	public onMarketplaceClose(): void {
		this.marketplace.pageNumber = this.marketplace.initialMarketplacePageNumber;
		this.marketplace.searchMarketplace = "";
	}

	// If we want to search from backend, then we have to activate the tagsubject
	public searchData(event: { term: string; items: SingleItemModel[] }): void {
		// this.tagsSubject$.next({event,searchType});
		this.marketplace.searchMarketplace = event.term;
	}

	// Mainly, firing the selected filter values to the master component
	public onDataChange(event, filterType: FilterType, indexInArray?): void {
		if (filterType === this.FilterType.timeline) {
			this.mixpanelService.track("Timeline Selected - " + event.label);
			this.selectedTimeline = event;
		} else if (filterType === this.FilterType.marketplace) {
			this.marketplace.selectedMarketplace = event;
			if (this.marketplace.searchMarketplace.length === 0)
				this.localStorageService.removeItem("filterDataMP");
		} else if (filterType === this.FilterType.market_share) {
		}
		this.fireSelectedFilter(filterType);
	}

	onCustomDateUpdate(range) {
		if (this.isFirstTime) {
			this.isFirstTime = false;
			return;
		}
		// TODO: this method automatically getting called at initial without updating the date, thats why isFirstTime check.
		if (this.isCalendarRangeClicked && this.isTheCalendarForMarketShare) return;
		else {
			if (range.startDate != null && range.endDate != null) {
				this.customDate = { ...range };
				this.selectedDate = { ...this.customDate };
				this.isDoneActionPerformed = true;
				this.fireSelectedFilter();
			} else {
				this.selectedDate = { ...this.customDate };
				//this.fireSelectedFilter();
			}
		}
		this.selectedDate = JSON.parse(JSON.stringify(this.selectedDate))
		this.selectedDate.startDate = dayjs(this.selectedDate.startDate).utc()
		this.selectedDate.endDate = dayjs(this.selectedDate.endDate).utc()
	}
	gt(key: string): string {
		return this.commonFunctions.getSpecificTranslation(key);
	}
	getNumberDate(date): any | void {
		if (date) {
			let numberDate = date.format("L").split("/");
			if (numberDate?.length == 3) {
				return numberDate[2] + "-" + numberDate[0] + "-" + numberDate[1];
			}
			return;
		}
	}
	public onMetricViewChange(metric: string): void {
		this.defaultMetricView = metric;
		this.fireSelectedFilter();
	}

	public onRadioChange() {
		this.fireSelectedFilter();
	}
	public checkSelectedOption(item) {
		if (this.marketplace?.selectedMarketplace.length) {
			return this.marketplace?.selectedMarketplace
				.map((val) => val.id)
				.includes(item.id);
		} else return false;
	}

	public checkReqSubmodules() {
		const validURLlist = [
			"/sales/sales-performance",
			"/sales/brand-analysis",
			"/sales/dealer-analysis",
			"/sales/sku-analysis"
		];
		return validURLlist.includes(this.route["_routerState"]["snapshot"]["url"]);
	}
	public onRangeClick = (event) => {
		this.isCalendarRangeClicked = true;
		this.customDate = { startDate: event.dates[0], endDate: event.dates[1] };
		this.selectedDate = { ...this.customDate, label: event.label };
		this.fireSelectedFilter();
	};

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
		this.isDoneActionPerformed = false;
	}

	public patchMarketplaceFilter() {
		this.dashboardService
			.getMarketplaceList(
				this.marketplace.pageNumber || 1,
				this.marketplace.searchMarketplace,
				this.router.url.includes("scorecard")
					? ""
					: this.commonFunctions.currencySelected?.id,
				this.router.url.split("/").slice(1).join("-"),
				this.filterData.id,
				this.marketPlaceTimeline.label
					? `&filter[timeline]=${
							MarketShareTimelineType[this.marketPlaceTimeline.label]
					  }`
					: `&filter[start_date]=${this.getNumberDate(
						dayjs(this.marketPlaceTimeline.startDate)
					  )}&filter[end_date]=${this.getNumberDate(
						dayjs(this.marketPlaceTimeline.endDate)
					  )}`
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: MarketplaceModel) => {
				if (!res.data) return;
				this.marketplace.selectedMarketplace = res["data"];
				this.fireSelectedFilter();
			});
	}

	public onOpenCloseDropdown(type) {
		this.dropdownOpenStatus[type] = !this.dropdownOpenStatus[type];
	}
}
