import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { CommonSearchModel } from "@ds-shared/models/common.model";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { Subject, Subscription, finalize, takeUntil } from "rxjs";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { trigger, transition, style, animate } from "@angular/animations";
import {
	HarmonisedListService,
	HarmonisationService
} from "@ds-private-layouts/configuration/harmonisation/harmonisation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HarmonisationUnmapActionComponent } from "@ds-shared/common-components/actions/harmonisation-unmap-action/harmonisation-unmap-action.component";
import { ActivatedRoute, Router } from "@angular/router";
import { LOCALFILTER } from "@ds-shared/enums/common.enum";
import { FilterList, ILOCAL } from "@ds-shared/models/local-filter.model";
import {
	HarmonisedEntityResData,
	RawAttributes,
	RawEntityResponseData
} from "@ds-shared/models/harmonisation.model";
import { CustomValidationsService } from "@ds-shared/common-services/utility-services/custom-validations.service";
@Component({
	selector: "app-harmonisation-mapping",
	templateUrl: "./harmonisation-mapping.component.html",
	styleUrls: ["./harmonisation-mapping.component.scss"],
	animations: [
		trigger("fadeAnimation", [
			transition(":enter", [
				style({ opacity: 0 }),
				animate("300ms ease-in", style({ opacity: 1 }))
			]),
			transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))])
		])
	]
})
export class HarmonisationMappingComponent implements OnInit, OnDestroy {
	@Input() public harmonisationType: string;
	@Input() public itemDetails;
	@Output() public closePanel: EventEmitter<{
		closePanel: boolean;
		refreshGrid: boolean;
	}> = new EventEmitter();
	@ViewChild("selectHarmonisedEntity")
	selectHarmonisedEntity: NgSelectComponent;
	@Input() public isPaginationWithoutRoute: boolean;
	@Output() paginationNo = new EventEmitter();
	@Output() paginationSize = new EventEmitter();
	@Output() public maxSize: number = 1;
	public page: number = 1;
	public pageSize: number;
	public itemPerPage: number = 10;
	public harmonisedSearch: UntypedFormControl = new UntypedFormControl("");
	public selectedPage: number;
	public currentPage: number = 1;
	public selectedPage_size: number = 10;
	public goToPage: number = null;
	public searchRawObj: CommonSearchModel = {
		text: "",
		placeholder: "Search",
		style: { width: "12rem" }
	};
	rawGrid: {
		rawGridList: any;
		isLoading: boolean;
		page: number;
		pageSize: number;
		itemPerPage: number;
		clearLastCallSubscription: Subscription;
		totalCount: number;
		checkedIds: any;
		meta: any;
	} = {
		rawGridList: [],
		isLoading: false,
		page: 1,
		pageSize: undefined,
		itemPerPage: 10,
		clearLastCallSubscription: undefined,
		totalCount: 0,
		checkedIds: [],
		meta: {}
	};
	harmonisedGrid = {
		harmonisedGridList: [],
		isLoading: false,
		page: 1,
		pageSize: 500,
		itemPerPage: 400,
		clearLastCallSubscription: undefined,
		selectedHarmonised: undefined,
		filteredGridList: []
	};
	private destroy$: Subject<boolean> = new Subject();
	public isMappingInProgress: boolean = false;
	private parentGridRefreshRequiredWhenClosed: boolean = false;
	public variantData = {};
	public variantSelected = {};
	public mappedSelected = [];
	public optionalFilters: FilterList;
	public currentSelectedCurrency: number;
	constructor(
		public commonFunctions: CommonFunctionsService,
		private harmonisedListService: HarmonisedListService,
		private customValidations: CustomValidationsService,
    	private harmonisationService: HarmonisationService,
		private notificationMessageService: NotificationService,
		private modalService: NgbModal,
		public router: Router,
		public route: ActivatedRoute
	) {}

	public permittedLocalFilter: ILOCAL[] = [
		LOCALFILTER.STORE,
		LOCALFILTER.BRAND,
		LOCALFILTER.CATEGORY_L1,
		LOCALFILTER.CATEGORY_L2,
		LOCALFILTER.CATEGORY,
		LOCALFILTER.STATUS,
		LOCALFILTER.RETAIL_CHANNEL,
		LOCALFILTER.COUNTRY,
		LOCALFILTER.SEGMENT
	];
	public optionalFilterParams = {
		filterRequired: {
			store: true,
			brand: true,
			category: true,
			category_l1: true,
			category_l2: true,
			segment: true,
			status: true,
			retail_channel: true,
			aiService: false,
			country: true
		}
	};

	ngOnInit(): void {
		this.searchRawObj = {
			...this.searchRawObj,
			placeholder: ["store", "product"].includes(this.harmonisationType)
				? `Search name or ${
						this.harmonisationType === "product" ? "sku no." : "reg no."
				  }`
				: "Search"
		};
		if (this.itemDetails?.id) this.rawGrid.checkedIds = [this.itemDetails?.id];
		this.rawGridCall();
		this.harmonisedGridCall();
	}

	onRawSearch(text: string) {
		this.searchRawObj = { ...this.searchRawObj, text: text };
		this.rawGrid.rawGridList = [];
		this.rawGrid.page = 1;
		this.rawGridCall();
	}

	onHarmonisedSearch() {}

	public rawGridCall(): void {
		if (!this.currentPage) return;
		this.rawGrid.isLoading = true;
		this.rawGrid.clearLastCallSubscription?.unsubscribe();
		const param: any = {};
		param["harmonisation_type"] = this.harmonisationType;
		param["currency_id"] = this.optionalFilters?.country?.selected?.id
			? this.optionalFilters?.country?.selected?.id
			: this.itemDetails?.attributes?.currency?.id
			? this.itemDetails?.attributes?.currency?.id
			: this.commonFunctions.currencySelected?.id;
		param["search"] = this.commonFunctions.encodeURi(
			this.searchRawObj.text?.trim()
		);
		if (this.harmonisationType !== "product") param["status"] = "Unmapped";
		param["page"] = this.rawGrid.page || 1;
		param["size"] = this.rawGrid.pageSize || 10;
		const optionalParams = this.commonFunctions.reduceOptionalParams(
			this.permittedLocalFilter,
			this.optionalFilters
		);
		Object.keys(param).forEach((item) => {
			if (
				(Array.isArray(param[item]) && !param[item]?.length) ||
				(!Array.isArray(param[item]) && !param[item])
			)
				delete param[item];
		});
		this.rawGrid.clearLastCallSubscription = this.harmonisationService
			.getRawEntity(param, optionalParams)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: {
					context_code: number;
					data: RawEntityResponseData[];
					meta: any;
					summary: string;
				}) => {
					this.rawGrid.isLoading = false;
					this.rawGrid.meta = res.meta;
					this.rawGrid.rawGridList = [...this.rawGrid.rawGridList, ...res.data];
					this.rawGrid.page = res["meta"]["next_page"];
					this.rawGrid.totalCount = res["meta"]["total_count"];
				},
				(error: any): void => {
					this.rawGrid.isLoading = false;
				}
			);
	}
	public onPageNoChange(eve: number): void {
		if (eve) {
			this.currentPage = eve;
			this.rawGridCall();
		}
	}
	public onPageSizeChange(eve: number): void {
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.rawGridCall();
	}

	public moveSelectedListToTop(list) {
		const index = list.findIndex((obj) => obj.id === this.itemDetails.id);
		if (index !== -1) {
			const removedItem = list.splice(index, 1)[0];
			list.unshift(removedItem);
		}

		return [...list];
	}

	public harmonisedGridCall(currencySelected?: number): void {
		this.harmonisedGrid.clearLastCallSubscription?.unsubscribe();
		this.harmonisedGrid.isLoading = true;
		this.harmonisedGrid.harmonisedGridList = [];
		this.harmonisedGrid.filteredGridList = [];
		const param:
			| {
					harmonisation_type: string;
					currency_id: number;
					page: number;
					size: number;
			  }
			| {} = {};
		param["harmonisation_type"] = this.harmonisationType;
		param["currency_id"] = currencySelected
			? currencySelected
			: this.commonFunctions.currencySelected?.id;
		param["page"] = this.harmonisedGrid.page || 1;
		param["size"] = this.harmonisedGrid.pageSize || 10;
		Object.keys(param).forEach((item) => {
			if (
				(Array.isArray(param[item]) && !param[item]?.length) ||
				(!Array.isArray(param[item]) && !param[item])
			)
				delete param[item];
		});
		this.harmonisedGrid.clearLastCallSubscription = this.harmonisationService
			.getHarmonisationEntity(param)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: {
					context_code: string;
					data: HarmonisedEntityResData[];
					meta: any;
					summary: string;
				}) => {
					this.harmonisedGrid.isLoading = false;
					this.harmonisedGrid.filteredGridList = [...res.data];
					this.harmonisedGrid.harmonisedGridList = [...res.data];
				},
				(error: any): void => {
					this.harmonisedGrid.isLoading = false;
				}
			);
	}

	public onRawEntityCheckUncheck(
		eventDetails,
		rawEntity: { id: number; attributes: RawAttributes; type: string }
	) {
		if (eventDetails.target.checked) {
			this.rawGrid.checkedIds.push(rawEntity.id);
			if (this.harmonisationType == "product")
				this.getVariantList(rawEntity.id);
		} else {
			const index = this.rawGrid.checkedIds.indexOf(rawEntity.id);
			if (index !== -1) {
				this.rawGrid.checkedIds.splice(index, 1);
			}
			if (this.harmonisationType === "product")
				delete this.variantSelected[rawEntity.id];
		}
	}

	public onVariantCheckUncheck(eventDetails, rawEntity, variant) {
		if (eventDetails.target.checked) {
			if (variant) this.variantSelected[rawEntity.id].push(variant.id);
			else
				this.variantSelected[rawEntity.id] = this.variantData?.[rawEntity.id]?.[
					"list"
				].map((item) => item.id);
		} else {
			if (variant) {
				const index = this.variantSelected[rawEntity.id].indexOf(variant.id);
				if (index !== -1) {
					this.variantSelected[rawEntity.id].splice(index, 1);
				}
			} else
				this.variantSelected[rawEntity.id] = JSON.parse(JSON.stringify([]));
		}
	}

	public onHarmonisedEntitySelected(harmonisedDetails) {
		this.harmonisedGrid.selectedHarmonised = harmonisedDetails;
	}

	public onMapping() {
		const payload = {
			harmonisation_type: this.harmonisationType,
			map_ids: [...this.rawGrid.checkedIds],
			harmonised_id: this.harmonisedGrid.selectedHarmonised.id
		};
		const harmonisedMappedData =
			this.harmonisedGrid.selectedHarmonised.attributes.mapped_details.map(
				(item) => item.id
			);
		if (this.harmonisationType === "product") {
			Object.keys(this.variantSelected).forEach((data: any) => {
				data = parseInt(data);
				if (
					harmonisedMappedData.includes(data) &&
					this.variantSelected[data].length
				) {
					const index = payload["map_ids"].indexOf(data);
					if (index !== -1) {
						payload["map_ids"].splice(index, 1);
					}
				}
				payload["map_ids"].push(...this.variantSelected[data]);
			});
		}
		this.isMappingInProgress = true;
		this.harmonisationService
			.udpateMapping(payload)
			.pipe(
				finalize(() => (this.isMappingInProgress = false)),
				takeUntil(this.destroy$)
			)
			.subscribe(
				(res) => {
					this.notificationMessageService.setMessage(
						res["context_code"],
						res["summary"]
					);
					this.harmonisedGrid.selectedHarmonised = undefined;
					this.parentGridRefreshRequiredWhenClosed = true;
					this.harmonisedGridCall();
					this.rawGrid.checkedIds = [];
					this.variantSelected = {};
					if (this.harmonisationType !== "product") {
						this.rawGrid.rawGridList = [];
						this.rawGrid.page = 1;
						this.rawGridCall();
					}
					// this.closePanel.emit({closePanel:true, refreshGrid:this.parentGridRefreshRequiredWhenClosed})
					// this.parentGridRefreshRequiredWhenClosed = false;
				},
				(err: any) => {
					this.notificationMessageService.setMessage(
						err["error"]["context_code"],
						err["error"]["summary"]
				
			);
		})
  }

  public onAddNewHarmonisedEntity = (term: string) => {
    this.selectHarmonisedEntity.close();
    this.addHarmonisationData(term)
  }

  public addHarmonisationData(term: string){
	if(this.customValidations.validateInputString({value: term})?.fieldMessage) {
        this.notificationMessageService.setMessage(1100, this.customValidations.validateInputString({value: term})?.fieldMessage)
    }
	else {
		this.harmonisationService.addHarmonisation(this.harmonisationType, { name: term, currency_id: this.commonFunctions.currencySelected?.id }).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
			if(res){
        this.notificationMessageService.setMessage(
          res["context_code"],
          res["summary"]
          );
        this.parentGridRefreshRequiredWhenClosed = true;
				this.harmonisedGridCall();
			}
		})
	}
	}
	
	public onHarmonisedEntitySearch(event) {
		if (!event.term.length) {
			this.harmonisedGrid.filteredGridList = [
				...this.harmonisedGrid.harmonisedGridList
			];
		} else {
			this.harmonisedGrid.filteredGridList =
				this.harmonisedGrid.harmonisedGridList.filter((item) =>
					item.attributes.name.toLowerCase().includes(event.term.toLowerCase())
				);
		}
		this.removeHoverStylesForAddTag();
		return this.harmonisedGrid.harmonisedGridList.filter((item) =>
			item.attributes.name.toLowerCase().includes(event.term.toLowerCase())
		);
	}

	private removeHoverStylesForAddTag() {
		// Get all the parent elements with class .option under ng-dropdown-panel
		const parentElements = document.querySelectorAll(
			".ng-dropdown-panel-items .ng-option-marked"
		);
		// Loop through each parent element
		parentElements.forEach((parentElement) => {
			// Get the child elements with class .ng-tag-label
			const childElements = parentElement.querySelectorAll(".ng-tag-label");
			// Check if the parent element contains the child elements
			if (childElements.length > 0) {
				// Disable hover styles by adding a class to the parent element
				parentElement.classList.add("disable-hover-styles"); // check style file, definition is there
			}
		});
	}

	public onPanelClose() {
		this.closePanel.emit({
			closePanel: true,
			refreshGrid: this.parentGridRefreshRequiredWhenClosed
		});
		this.parentGridRefreshRequiredWhenClosed = false;
	}

	public onHarmonisedSearchClose() {
		if (!this.harmonisedGrid.selectedHarmonised) {
			this.harmonisedGrid.filteredGridList = [
				...this.harmonisedGrid.harmonisedGridList
			];
		} else {
			this.harmonisedGrid.filteredGridList =
				this.harmonisedGrid.harmonisedGridList.filter(
					(item) => item.id === this.harmonisedGrid.selectedHarmonised.id
				);
		}
	}

	public getVariantList(parent_product_id: number) {
		if (!(parent_product_id in this.variantData)) {
			this.variantData[parent_product_id] = JSON.parse(
				JSON.stringify({
					isLoading: true,
					list: []
				})
			);
			this.harmonisationService
				.getVariantList(parent_product_id, true)
				.pipe(takeUntil(this.destroy$))
				.subscribe((res: any) => {
					this.variantData[parent_product_id] = {
						isLoading: false,
						list: res.data
					};
					if (this.rawGrid.checkedIds?.includes(parent_product_id))
						this.variantSelected[parent_product_id] = this.variantData?.[
							parent_product_id
						]?.["list"].map((item) => item.id);
				});
		} else {
			if (this.rawGrid.checkedIds?.includes(parent_product_id)) {
				if (!(parent_product_id in this.variantSelected))
					this.variantSelected[parent_product_id] = this.variantData?.[
						parent_product_id
					]?.["list"].map((item) => item.id);
			}
		}
	}

	public onHarmonisedMappedCheckUncheck(eventDetails, rawData, list) {
		if (eventDetails.target.checked) {
			if (rawData) this.mappedSelected.push(rawData.id);
			else {
				this.mappedSelected = list.map((item) => item.id);
			}
		} else {
			if (rawData) {
				const index = this.mappedSelected.indexOf(rawData.id);
				if (index !== -1) {
					this.mappedSelected.splice(index, 1);
				}
			} else {
				this.mappedSelected = JSON.parse(JSON.stringify([]));
			}
		}
	}

	public deleteMapping(harmonisedId, mappings) {
		const payload = {
			harmonisation_type: this.harmonisationType,
			unmap_ids: mappings,
			harmonised_id: harmonisedId
		};
		this.harmonisationService
			.udpateMapping(payload)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: { context_code: number; data: any; summary: string }) => {
					this.notificationMessageService.setMessage(
						res["context_code"],
						res["summary"]
					);
					this.harmonisedGrid.selectedHarmonised = undefined;
					this.parentGridRefreshRequiredWhenClosed = true;
					this.mappedSelected = JSON.parse(JSON.stringify([]));
					this.harmonisedGridCall();
					if (this.harmonisationType !== "product") {
						this.rawGrid.checkedIds = [];
						this.rawGrid.rawGridList = [];
						this.rawGrid.page = 1;
						this.rawGridCall();
					}
				},
				(err) => {
					this.notificationMessageService.setMessage(
						err.error["context_code"],
						err.error["summary"]
					);
				}
			);
	}

	openHarmonisedUnmapModal(
		harmonised_id: number,
		mappings: number[],
		item: HarmonisedEntityResData
	) {
		const modalRef = this.modalService.open(HarmonisationUnmapActionComponent, {
			centered: true,
			windowClass: "customModal harmonisedConfirmModal",
			keyboard: false,
			backdrop: "static"
		});
		const type = this.harmonisationType;
		const mod_type = `${
			type === "category" && mappings.length > 1
				? "Categories"
				: type + (mappings.length > 1 ? "s" : "")
		}`;
		const tracker_plural =
			item.attributes.linked_tracker.length > 1 ? "trackers" : "tracker";
		const tracker_text = item.attributes.linked_tracker.length
			? `<b>${
					item.attributes.name
			  }</b> is linked to <b>${item.attributes.linked_tracker
					.map((data) => data.name)
					.join(", ")}</b> ${tracker_plural}.`
			: "";
		modalRef.componentInstance.popupInfo = {
			mainTitle: `Unmap ${this.commonFunctions.capitalizeFirstLetter(
				mod_type
			)}`,
			submitTitle: `Yes, Unmap ${this.commonFunctions.capitalizeFirstLetter(
				mod_type
			)}`,
			description: `${tracker_text}\nAre you sure you want to upmap <b>${mappings.length}</b> selected ${mod_type} from <b>${item.attributes.name}</b> ?`
		};
		modalRef.result.then(
			(result) => {
				if (!result) return;
				this.deleteMapping(harmonised_id, mappings);
			},
			(_reason) => {}
		);
	}

	onOptionalFilterUpdate(event: FilterList) {
		this.optionalFilters = { ...event };
		this.rawGrid.rawGridList = [];
		this.rawGrid.page = 1;
		this.rawGridCall();
		if (
			this.currentSelectedCurrency !==
			this.optionalFilters?.country?.selected?.id
		) {
			this.harmonisedGridCall(this.optionalFilters["country"]?.selected.id);
		}
		this.currentSelectedCurrency =
			this.optionalFilters["country"]?.selected?.id;
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
