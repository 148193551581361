import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NotificationsService } from '../notifications.service';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { TabListModel } from '@ds-shared/models/notification.model';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';

@Component({
  selector: 'app-historic-notifications',
  templateUrl: './historic-notifications.component.html',
  styleUrls: ['./historic-notifications.component.scss']
})
export class HistoricNotificationsComponent implements OnInit {

  @Input() allNotificationsData: any;
  @Input() pageNumber: number;
  @Input() isHistoricPresent: any;
  @Output() closeFunction = new EventEmitter<boolean>()
  @Output() refreshHistoricNotifications = new EventEmitter<boolean>();
  @Input() destroy$: Subject<boolean> = new Subject()
  @Input() mappedTypeValue: string;
  @Input() type: string;
  public dataValue: any;
  public mappedType: any;
  public allNotificationsSubscription: Subscription;
  public markNotificationUnreadSubscription: Subscription;
  public tabList: TabListModel[] = [
    {
      id: 1,
      name: "Stores",
      value: "store",
      routePath: 'stores'
    },
    {
      id: 2,
      name: "Products",
      value: "product",
      routePath: 'products'
    },
    {
      id: 3,
      name: "Keywords",
      value: "keyword",
      routePath: 'keywords'
    },
    {
      id: 4,
      name: "Categories",
      value: "category",
      routePath: 'categories'
    },
    {
      id: 5,
      name: "Brands",
      value: "brand",
      routePath: 'brands'
    }
  ];
  constructor(private notificationService: NotificationsService,
    private route: ActivatedRoute, private summaryService: NotificationService, private commonFunctions: CommonFunctionsService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.allNotificationsData) {
      this.allNotificationsData = changes.allNotificationsData.currentValue;
    }
  }

  getNotificationsData(type) {
    if (this.pageNumber != null) {
      if (this.type == 'dashboard') {
        this.dataValue = this.mappedTypeValue;
      } else {
        this.dataValue = this.getTypeFromRoute(type);
      }
      this.allNotificationsSubscription = this.notificationService.getHistoricNotifications(false, this.dataValue, this.pageNumber).subscribe((res: any) => {
        if (res) {
          for (let note of res['data']) this.allNotificationsData = [...this.allNotificationsData, note];
          this.pageNumber = res['meta']['next_page'];
        }
      }, (err: any) => {
        this.allNotificationsData = [];
        this.pageNumber = 1;
      });
    }
  }

  onScroll() {
    if (this.type == 'dashboard') {
      this.getNotificationsData(this.mappedType);
    } else {
      this.getNotificationsData(this.commonFunctions.getURLSegments()[1]);
    }
  }

  onMarkUnread(id) {
    this.markNotificationUnreadSubscription = this.notificationService.markNotificationUnread(id).subscribe((res: any) => {
      if (res) {
        this.summaryService.setMessage(res['context_code'], res['summary']);
        this.refreshHistoricNotifications.emit(true);
      }
    }, (err: any) => {
      this.summaryService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }

  private getTypeFromRoute(type) {
    const obj = this.tabList.find(o => o.routePath === type);
    return obj.value
  }

  ngOnDestroy(): void {
    if (this.allNotificationsSubscription) this.allNotificationsSubscription.unsubscribe();
    if (this.markNotificationUnreadSubscription) this.markNotificationUnreadSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
