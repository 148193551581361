import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	OnDestroy
} from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { NotificationsService } from "@ds-private-layouts/notifications/notifications.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import {
	debounceTime,
	distinctUntilChanged,
	finalize,
	takeUntil
} from "rxjs/operators";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import {
	TargteKPI1Operators,
	TargteKPI2Operators
} from "@ds-static-data/notification.data";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
@Component({
	selector: "app-notification-keyword-detail",
	templateUrl: "./notification-keyword-detail.component.html",
	styleUrls: ["./notification-keyword-detail.component.scss"]
})
export class NotificationKeywordDetailComponent implements OnInit, OnDestroy {
	@Input() notificationData: any;
	public rangeError = false;
	public marketplaceListSubscription: Subscription;
	public marketplaceList: any[] = [];
	public marketplacePageNumber: number = 1;
	public productListSubscription: Subscription;
	public productList: any[] = [];
	public initialProductList = [];
	public productPageNumber: number = 1;
	public initialProductPageNumber = 1;
	public metricListSubscription: Subscription;
	public actionType: string = "";
	public productForm: UntypedFormGroup;
	public submitted: boolean = false;
	public currentKPISubscription: Subscription;
	public currentKPIValue: any;
	public updateSubscription: Subscription;
	public addSubscription: Subscription;
	public notificationInfo: any;
	public loadedMarketplaceList: boolean = false;
	public loadedProductList: boolean = false;
	public loadedkpiList: boolean = false;
	public searchProduct: any = "";
	public tagsSubject$ = new Subject<{}>();
	public $destroy: Subject<boolean> = new Subject();
	public permissions: RolePermissions = new RolePermissions();
	public targetKPIOperatorList1 = TargteKPI1Operators;
	public targetKPIOperatorList2 = TargteKPI2Operators;
	public targetKPIOperator1Data = {
		list: this.targetKPIOperatorList1,
		isLoading: false,
		clearLastCallSubscription: null
	};
	public targetKPIOperator2Data = {
		list: this.targetKPIOperatorList2,
		isLoading: false,
		clearLastCallSubscription: null
	};
	public keywordList = {
		list: [],
		isLoading: false,
		clearLastCallSubscription: null
	};

	@Output() onSuccessAction: EventEmitter<any> = new EventEmitter();
	@Output() onCancelAction: EventEmitter<any> = new EventEmitter();
	private campaignStatusCount = 0;
	constructor(
		private notificationService: NotificationsService,
		private notificationMessageService: NotificationService,
		private route: ActivatedRoute,
		public commonFunctions: CommonFunctionsService,
		private accessMatricService: AccessMatrixService,
		private globalService: GlobalService
	) {
		this.permissions = this.accessMatricService.getPermissionByModule(
			AccessMatrix.Notifications
		);
		this.searchInit();
		this.campaignStatusCheck();
	}

	private campaignStatusCheck() {
		this.globalService
			.getCampaignStatus()
			.pipe(takeUntil(this.$destroy))
			.subscribe((res) => {
				this.campaignStatusCount = res["data"]["profiles"];
			});
	}

	searchInit() {
		this.tagsSubject$
			.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.$destroy))
			.subscribe(
				(res: any) => {
					const { event, searchType } = res;
					this.onSearchFire(event, searchType);
				},
				(err) => {}
			);
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params: any) => {
			this.notificationInfo = params?.notificationInfo
				? JSON.parse(
						this.commonFunctions.getDecodedData(params?.notificationInfo)
				  )
				: null;
		});
		this.getMarketplaceList();
		this.actionType = String(
			this.route["_routerState"]["snapshot"].url
		)?.includes("add-notification")
			? "add"
			: this.commonFunctions.getURLSegments()[2];
		this.productForm = new UntypedFormGroup({
			select_type: new UntypedFormControl(
				this.getDefaultValue("competitor") != null
					? this.getDefaultValue("competitor")
					: false
			),
			select_marketplace: new UntypedFormControl(
				this.getDefaultValue("marketplace"),
				Validators.required
			),
			select_product: new UntypedFormControl(
				this.getDefaultValue("product"),
				Validators.required
			),
			current_kpi: new UntypedFormControl(
				this.getDefaultValue("current_kpi_value"),
				Validators.required
			),
			note_product_kpi_value: new UntypedFormControl(
				this.getDefaultValue("target_kpi_value"),
				[Validators.required]
			),
			frequency: new UntypedFormControl(
				this.getDefaultValue("frequency") == null
					? "Repeat"
					: this.getDefaultValue("frequency"),
				Validators.required
			),
			target_kpi_operator_1: new UntypedFormControl(
				this.getDefaultValue("operator")
			),
			note_product_kpi_value_2: new UntypedFormControl(
				this.getDefaultValue("target_kpi_value_2")
			),
			target_kpi_operator_2: new UntypedFormControl(
				this.getDefaultValue("operator_2")
			),
			select_keyword: new UntypedFormControl(
				this.getDefaultValue("keyword"),
				Validators.required
			)
		});
		this.productForm.controls["current_kpi"].disable();
		this.productForm.controls["select_keyword"].disable();
		this.productForm.controls["select_product"].disable();
		this.disableRangeOperators();
		if (this.actionType == "edit") {
			this.productForm.controls["select_type"].disable();
			this.productForm.controls["select_marketplace"].disable();
			this.productForm.controls["target_kpi_operator_1"].enable();
			this.productForm.controls["note_product_kpi_value"].enable();
			this.onFirstOperatorChange(
				this.productForm.controls["target_kpi_operator_1"].value
			);
			if (this.productForm.controls["target_kpi_operator_2"].value) {
				this.productForm.controls["note_product_kpi_value_2"].enable();
			}
		} else if (this.notificationInfo) {
			this.productForm.controls["select_marketplace"].disable();
			this.productForm.controls["select_keyword"].disable();
			this.productForm.controls["select_product"].disable();
		}
	}
	getDefaultValue(forLabel: string): any {
		if (this.actionType == "edit") {
			if (forLabel == "current_kpi_value") {
				return this.notificationData?.["attributes"]?.[forLabel] != null
					? this.notificationData?.["attributes"]?.[forLabel]
					: "-";
			} else if (forLabel == "target_kpi") {
				return { name: this.notificationData?.["attributes"]?.[forLabel] };
			} else if (forLabel == "operator" || forLabel == "operator_2") {
				const operatorMap = {
					operator: this.targetKPIOperator1Data,
					operator_2: this.targetKPIOperator2Data
				};
				return operatorMap[forLabel]["list"].find(
					(ele) =>
						ele.value === this.notificationData?.["attributes"]?.[forLabel]
				);
			}
			return this.notificationData?.["attributes"]?.[forLabel];
		} else if (this.notificationInfo) {
			if (forLabel == "marketplace") return this.notificationInfo?.marketplace;
			else if (forLabel == "name") return this.notificationInfo?.name;
			else return null;
		}
		return null;
	}
	getMarketplaceList() {
		if (this.marketplacePageNumber) {
			this.marketplaceListSubscription?.unsubscribe();
			this.loadedMarketplaceList = true;
			this.marketplaceListSubscription = this.notificationService
				.getMarketplaceList(this.marketplacePageNumber, {
					"filter[profile_types]": ["keyword"]
				})
				.subscribe(
					(res: any) => {
						if (res) {
							this.marketplaceList = [...this.marketplaceList, ...res?.data];
							this.marketplacePageNumber = res?.meta?.next_page;
							this.loadedMarketplaceList = false;
						}
					},
					(err: any) => {
						this.marketplaceList = [];
						this.marketplacePageNumber = 1;
						this.loadedMarketplaceList = false;
					}
				);
		}
	}
	getProductList() {
		if (this.productPageNumber) {
			this.productListSubscription?.unsubscribe();
			this.loadedProductList = true;
			this.productListSubscription = this.notificationService
				.getProductList(
					this.productForm.controls["select_marketplace"]?.value?.id,
					this.productPageNumber,
					this.searchProduct,
					this.productForm.controls["select_keyword"]?.value?.id
				)
				.subscribe(
					(res: any) => {
						if (res) {
							this.productList = [...this.productList, ...res?.data];
							this.productPageNumber = res?.meta?.next_page;
							this.loadedProductList = false;
							if (
								res?.meta?.current_page === 1 &&
								!this.initialProductList.length
							) {
								this.initialProductList = JSON.parse(
									JSON.stringify(this.productList)
								);
								this.initialProductPageNumber = this.productPageNumber;
							}
						}
					},
					(err: any) => {
						this.resetInitials();
						this.loadedProductList = false;
					}
				);
		}
	}
	getCurrentKPIValue(productId: any) {
		this.currentKPISubscription?.unsubscribe();
		this.currentKPISubscription = this.notificationService
			.getCurrentKPIsValue(
				"keyword",
				productId,
				this.productForm.controls["select_type"]?.value,
				null,
				this.productForm.controls["select_keyword"]?.value?.id
			)
			.pipe(finalize(() => {}))
			.subscribe(
				(res: any) => {
					if (res) {
						this.currentKPIValue = res?.data?.value;
						this.productForm
							.get("current_kpi")
							.setValue(
								this.currentKPIValue !== null ? this.currentKPIValue : "-"
							);
					}
				},
				(err: any) => {
					this.currentKPIValue = null;
					this.productForm.get("current_kpi").setValue("-");
				}
			);
	}

	onResponseAction(response: any) {
		if (response) {
			this.onSuccessAction.emit();
			this.notificationMessageService.setMessage(
				response["context_code"],
				response["summary"]
			);
		}
	}
	onSubmit() {
		this.submitted = true;
		if (this.productForm.status == "VALID" && !this.rangeError) {
			const data = {
				target_value:
					this.productForm.controls["note_product_kpi_value"]?.value,
				repeat:
					this.productForm.controls["frequency"]?.value == "Repeat"
						? true
						: false,
				competitor: this.productForm.controls["select_type"]?.value,
				keyword: this.productForm.controls["select_keyword"]?.value?.id,
				product_id: this.notificationInfo
					? this.notificationInfo?.id
					: this.productForm.controls["select_product"]?.value?.id,
				operator:
					this.productForm.controls["target_kpi_operator_1"]?.value?.value,
				target_value_2:
					this.productForm.controls["note_product_kpi_value_2"]?.value,
				operator_2:
					this.productForm.controls["target_kpi_operator_2"]?.value?.value
			};
			if (this.actionType == "edit") {
				this.updateSubscription?.unsubscribe();
				this.updateSubscription = this.notificationService
					.updateNotification("keyword-config", data, this.notificationData?.id)
					.subscribe(
						(res: any) => {
							if (res) this.onResponseAction(res);
						},
						(err: any) => {
							this.notificationMessageService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			} else {
				this.addSubscription?.unsubscribe();
				this.addSubscription = this.notificationService
					.createNotification("keyword-config", data)
					.subscribe(
						(res: any) => {
							if (res) this.onResponseAction(res);
						},
						(err: any) => {
							this.notificationMessageService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			}
		}
	}

	public onKpiChange(eve) {
		if (this.productForm.controls["note_product_kpi_value_2"]?.value) {
			if (
				this.productForm.controls["note_product_kpi_value"].value >=
				this.productForm.controls["note_product_kpi_value_2"].value
			)
				this.rangeError = true;
			else this.rangeError = false;
		}

		if (this.productForm.controls["note_product_kpi_value"].invalid)
			this.productForm.controls["note_product_kpi_value_2"].disable();
		else {
			if (
				(this.productForm.controls["target_kpi_operator_1"]?.value?.value ===
					">" ||
					this.productForm.controls["target_kpi_operator_1"]?.value?.value ===
						">=") &&
				this.productForm.controls["target_kpi_operator_2"]?.value
			) {
				this.productForm.controls["note_product_kpi_value_2"].enable();
			}
		}
	}

	onCloseAction() {
		this.onCancelAction.emit();
	}
	resetForm(type: any) {
		if (type == "marketplace") {
			this.resetInitials();
			this.keywordList.list = [];
			this.resetByType(type);
			this.disableByType(type);
		} else if (type == "keyword") {
			this.resetInitials();
			this.resetByType(type);
			this.disableByType(type);
		} else if (type == "product") {
			this.resetByType(type);
			this.disableByType(type);
		}
	}

	onChange(event: any, type: any) {
		if (type == "marketplace") {
			this.resetForm("marketplace");
			if (event) {
				this.getKeywordList();
				this.productForm.controls["select_keyword"].enable();
			}
		} else if (type === "keyword") {
			this.resetForm("keyword");
			if (event) {
				this.getProductList();
				this.productForm.controls["select_product"].enable();
			}
		} else if (type == "product") {
			this.resetForm("product");
			if (event) {
				this.getCurrentKPIValue(
					this.notificationInfo
						? this.notificationInfo?.id
						: this.productForm.controls["select_product"]?.value?.id
				);
				this.productForm.controls["note_product_kpi_value"].enable();
				this.productForm.controls["target_kpi_operator_1"].enable();
				this.productForm.controls["target_kpi_operator_1"].setValue(
					this.targetKPIOperatorList1[4]
				);
			}
		}
	}
	updateButtonState() {
		if (this.actionType == "edit") {
			return (
				this.productForm.status != "VALID" ||
				(this.productForm.controls["note_product_kpi_value"]?.value ==
					this.notificationData?.attributes?.target_kpi_value &&
					this.productForm.controls["frequency"]?.value ==
						this.notificationData?.attributes?.frequency &&
					this.productForm.controls["note_product_kpi_value_2"]?.value ==
						this.notificationData?.attributes?.target_kpi_value_2 &&
					this.productForm.controls["target_kpi_operator_2"]?.value ==
						this.notificationData?.attributes?.operator_2 &&
					this.productForm.controls["target_kpi_operator_1"]?.value ==
						this.notificationData?.attributes?.operator)
			);
		} else return false;
	}
	searchData(event: any, searchType: any) {
		this.tagsSubject$.next({ event, searchType });
	}

	onSearchFire(event: any, searchType: any) {
		if (searchType == "product") {
			this.searchProduct = event?.term;
			if ((event?.term && event?.term.length >= 3) || event?.term.length == 0) {
				this.productPageNumber = 1;
				this.productList = [];
				this.getProductList();
			}
		}
	}

	onProductDropdownClose() {
		this.productList = this.initialProductList;
		this.productPageNumber = this.initialProductPageNumber;
		this.searchProduct = "";
	}

	public onFirstOperatorChange(eve) {
		if (eve?.value === ">" || eve?.value === ">=") {
			this.productForm.controls["target_kpi_operator_2"].enable();
		} else {
			this.productForm.controls["target_kpi_operator_2"].disable();
			this.productForm.controls["target_kpi_operator_2"].reset();
			this.productForm.controls["note_product_kpi_value_2"].reset();
			this.productForm.controls["note_product_kpi_value_2"].disable();
		}
	}

	public onSecondOperatorChange(eve) {
		if (eve) {
			this.productForm.controls["note_product_kpi_value_2"].enable();
		} else {
			this.productForm.controls["note_product_kpi_value_2"].disable();
			this.productForm.controls["note_product_kpi_value_2"].reset();
		}
	}

	public onNoteProductKpiValueChange2(eve) {
		if (eve.target.value) {
			if (
				this.productForm.controls["note_product_kpi_value"].value >=
				this.productForm.controls["note_product_kpi_value_2"].value
			)
				this.rangeError = true;
			else this.rangeError = false;
		} else this.rangeError = false;
	}
	disableRangeOperators() {
		this.productForm.controls["note_product_kpi_value"].disable();
		this.productForm.controls["target_kpi_operator_1"].disable();
		this.productForm.controls["target_kpi_operator_2"].disable();
		this.productForm.controls["note_product_kpi_value_2"].disable();
	}
	resetRangeOperators() {
		this.productForm.controls["target_kpi_operator_1"].reset();
		this.productForm.controls["note_product_kpi_value"].reset();
		this.productForm.controls["target_kpi_operator_2"].reset();
		this.productForm.controls["note_product_kpi_value_2"].reset();
	}
	resetInitials() {
		this.productList = [];
		this.initialProductList = [];
		this.productPageNumber = 1;
		this.initialProductPageNumber = 1;
		this.searchProduct = "";
	}
	disableByType(type) {
		if (type == "product" || type == "marketplace" || type == "keyword") {
			this.productForm.controls["current_kpi"].disable();
			this.disableRangeOperators();
		}
		if (type == "marketplace") {
			this.productForm.controls["select_keyword"].disable();
			this.productForm.controls["select_product"].disable();
		}
		if (type == "keyword") {
			this.productForm.controls["select_product"].disable();
		}
	}

	resetByType(type) {
		if (type == "product" || type == "marketplace" || type == "keyword") {
			this.productForm.controls["current_kpi"].reset();
			this.resetRangeOperators();
		}
		if (type == "marketplace") {
			this.productForm.controls["select_keyword"].reset();
			this.productForm.controls["select_product"].reset();
		}
		if (type == "keyword") this.productForm.controls["select_product"].reset();
	}
	private getKeywordList() {
		this.keywordList.clearLastCallSubscription?.unsubscribe();
		this.keywordList.isLoading = true;
		this.keywordList.list = [];
		this.keywordList.clearLastCallSubscription = this.notificationService
			.getKeywordList(
				this.productForm.controls["select_marketplace"]?.value?.id
			)
			.pipe(
				finalize(() => (this.keywordList.isLoading = false)),
				takeUntil(this.$destroy)
			)
			.subscribe((res: any) => {
				this.keywordList.list = res.data;
			});
	}
	ngOnDestroy(): void {
		this.keywordList.clearLastCallSubscription?.unsubscribe();
		this.marketplaceListSubscription?.unsubscribe();
		this.productListSubscription?.unsubscribe();
		this.metricListSubscription?.unsubscribe();
		this.currentKPISubscription?.unsubscribe();
		this.updateSubscription?.unsubscribe();
		this.addSubscription?.unsubscribe();
		this.tagsSubject$.next(null);
		this.tagsSubject$.unsubscribe();
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
