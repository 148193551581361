import { AccessMatrix } from "@ds-enums/access-matrix.enum";

export interface AccessMatrixModel {
  module_id?: number;
  module_name?: keyof typeof AccessMatrix;
  module_code?: string;
  read_access: boolean;
  export_access: boolean;
  write_access: boolean;
  full_access: boolean;
}

export class RolePermissions{
  read_access: boolean;
  export_access: boolean;
  write_access: boolean;
  full_access: boolean;
  module_name?: string;
  constructor(permissions?: AccessMatrixModel){
    this.read_access = permissions?permissions.read_access:false,
    this.export_access = permissions?permissions.export_access:false,
    this.write_access = permissions?permissions.write_access:false,
    this.full_access = permissions?permissions.full_access:false,
    this.module_name = permissions?permissions.module_name:''
  }
}
export interface AccessMatrixRolesModel {
  module_code: string;
  read_access: boolean;
  export_access: boolean;
  write_access: boolean;
  full_access: boolean;
}